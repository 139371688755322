import { useState } from "react";
import { BoonLogo } from "../../utils/boon-logo";
import Button from "../Button";
import WalletsPopover from "./WalletsPopover";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  useHover,
  useDismiss,
  useRole,
  useInteractions,
  FloatingFocusManager,
  safePolygon,
} from "@floating-ui/react";
import { arrowDown, circle } from "../../utils/icons";
import useWallet from "../../hooks/wallet/useWallet";
import { isMobile } from "react-device-detect";
import { Drawer } from "vaul";
import ConnectWalletBottomSheet from "./ConnectWalletBottomSheet";

const ConnectedWalletButton = ({
  walletAddress,
}: {
  walletAddress: string | null;
}) => {
  const displayWalletAddressShort =
    walletAddress && `${walletAddress.slice(0, 4)}`;

  const displayWalletAddressLong =
    walletAddress &&
    `${walletAddress.slice(0, 7)}...${walletAddress.slice(walletAddress.length - 7)}`;

  return (
    <div className="flex items-center justify-center">
      {circle()}
      <p className="ml-space-3 mr-space-3 text-surface-100 sm:hidden">
        {displayWalletAddressShort}
      </p>
      <p className="ml-space-3 mr-space-3 hidden text-surface-100 sm:block">
        {displayWalletAddressLong}
      </p>
      {arrowDown()}
    </div>
  );
};

const Navigation = () => {
  const { isWalletConnected, walletAddress } = useWallet();
  const [popoverOpen, setPopoverOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: popoverOpen,
    onOpenChange: setPopoverOpen,
    middleware: [offset({ mainAxis: 10, crossAxis: -85 }), flip()],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, {
    delay: { open: 50 },
    handleClose: safePolygon({
      buffer: 1,
    }),
  });
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    dismiss,
    role,
  ]);

  const togglePopover = () => setPopoverOpen((prev) => !prev);

  return (
    <div className="gradient-border-bottom flex h-[100px] items-center justify-between px-space-4 md:px-space-14">
      <div>
        <BoonLogo />
      </div>
      <div>
        {!isMobile && (
          <div ref={refs.setReference} {...getReferenceProps()}>
            <Button
              disabled
              text={isWalletConnected ? "" : "Connect Wallet"}
              onClick={togglePopover}
              content={
                isWalletConnected ? (
                  <ConnectedWalletButton walletAddress={walletAddress} />
                ) : (
                  <></>
                )
              }
            />
          </div>
        )}

        {isMobile && (
          <Drawer.Trigger asChild>
            <div>
              <Button
                text={isWalletConnected ? "" : "Connect Wallet"}
                content={
                  isWalletConnected ? (
                    <ConnectedWalletButton walletAddress={walletAddress} />
                  ) : (
                    <></>
                  )
                }
              />
            </div>
          </Drawer.Trigger>
        )}
        <ConnectWalletBottomSheet
          isOpen={popoverOpen && isMobile}
          setIsOpen={setPopoverOpen}
        />

        {popoverOpen && !isMobile && (
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              <WalletsPopover onConnect={() => setPopoverOpen(false)} />
            </div>
          </FloatingFocusManager>
        )}
      </div>
    </div>
  );
};

export default Navigation;
