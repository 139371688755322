import { GasPrice } from "@cosmjs/stargate";

export const RPC_URL = process.env.REACT_APP_RPC_URL || "";
export const REST_URL = process.env.REACT_APP_REST_URL || "";
export const GAS_PRICE = GasPrice.fromString(process.env.REACT_APP_GAS_PRICE || "0.025ukuji");
export const TOKEN = process.env.REACT_APP_TOKEN || "";
export const TOKEN_ADDRESS = process.env.REACT_APP_TOKEN_ADDRESS || "";
export const DAO_ADDRESS = process.env.REACT_APP_DAO_ADDRESS || "";
export const DAO_VOTING_ADDRESS = process.env.REACT_APP_DAO_VOTING_ADDRESS || "";
export const DAO_REWARDS_ADDRESS = process.env.REACT_APP_DAO_REWARDS_ADDRESS || "";
export const MDAO_ADDRESS = process.env.REACT_APP_MDAO_ADDRESS || "";
export const MDAO_VESTING_ADDRESS = process.env.REACT_APP_MDAO_VESTING_ADDRESS || "";
export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID || "";
export const VESTING_WHITELIST = process.env.REACT_APP_VESTING_WHITELIST || "";
export const TEST_NET = process.env.REACT_APP_TEST_NET || false;
