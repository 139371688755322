import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { ChartDataItem } from "../../../model";
import { Tooltip } from "react-tooltip";
import { useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import { divideAndFormatBoon } from "../../../utils";

interface DonutPieChartProps {
  chartDataItems: ChartDataItem[];
  mainTitle: string;
  mainValue: BigNumber;
  fractionDigits?: number;
}

export interface ChartDataItemNumber {
  name: string;
  value: number;
  color: string;
}

const DonutPieChart = ({
  chartDataItems: data,
  mainTitle,
  mainValue,
  fractionDigits = 0,
}: DonutPieChartProps) => {
  const [chartData, setChartData] = useState<ChartDataItemNumber[]>([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setChartData(data.map(item => ({
        ...item,
        value: item.value.toNumber()
      })));
    }, 100);

    return () => clearTimeout(timer);
  }, [data]);

  const totalNumberDisplay = divideAndFormatBoon(mainValue, fractionDigits);
  const totalNumberRawValue = divideAndFormatBoon(mainValue, 10);

  return (
    <div className="flex justify-center lg:items-start">
      <div className="relative flex items-center justify-center">
        <ResponsiveContainer minWidth={228} minHeight={228}>
          <PieChart>
            <Pie
              data={chartData}
              cx="50%"
              cy="50%"
              innerRadius="94%"
              outerRadius="100%"
              startAngle={90}
              endAngle={450}
              animationBegin={0}
              animationDuration={500} // Speed of chart animation
              paddingAngle={-4} // Negative value for overlapping
              dataKey="value"
              blendStroke={true} // Enable blending of strokes
              cornerRadius={10} // Rounded ends
            >
              {chartData.map((dataItem, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={dataItem.color}
                  style={{
                    outline: "none",
                  }}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="174"
          height="174"
          viewBox="0 0 174 174"
          fill="none"
          className="absolute"
        >
          <circle
            cx="87.0002"
            cy="86.9998"
            r="86.1306"
            fill="url(#paint0_linear_8173_996)"
          />
          <circle
            cx="87.0002"
            cy="86.9998"
            r="85.6306"
            stroke="url(#paint1_linear_8173_996)"
            strokeOpacity="0.1"
          />
          <defs>
            <linearGradient
              id="paint0_linear_8173_996"
              x1="87.0002"
              y1="0.869263"
              x2="87.0002"
              y2="173.13"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" stopOpacity="0.05" />
              <stop offset="0.9999" stopOpacity="0" />
              <stop offset="1" stopColor="white" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_8173_996"
              x1="87.0002"
              y1="0.869263"
              x2="87.0002"
              y2="173.13"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" stopColor="white" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            gap: "var(--space-3, 12px)",
          }}
        >
          <p
            className="body-extra-sm-regular-light"
            style={{ color: "rgba(255, 255, 255, 0.50)" }}
          >
            {mainTitle}
          </p>
          <p
            className="heading-md-bold-light cursor-default text-[25px] text-white"
            data-tooltip-id="dashboard-chart-tooltip"
            data-tooltip-content={totalNumberRawValue}
          >
            {totalNumberDisplay.toString().slice(0, 12) +
              (totalNumberDisplay.length < 12 ? "" : "...")}
          </p>
          <Tooltip id="dashboard-chart-tooltip" />
        </div>
      </div>
    </div>
  );
};

export default DonutPieChart;
