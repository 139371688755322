import { createSlice } from "@reduxjs/toolkit";
import { ToastType } from "../utils";

export interface NotificationsState {
  message: { content: string; type: ToastType } | null;
}

const initialState: NotificationsState = {
  message: null,
};

export const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState,
  reducers: {
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    resetMessage: (state) => {
      state.message = null;
    },
  },
});

export const { setMessage, resetMessage } = notificationSlice.actions;

export default notificationSlice.reducer;
