import { ChartDataItem } from "../../../model";
import VerticalDivider from "../../VerticalDivider";
import DashboardChartDataItem from "./DashboardChartDataItem";

const ChartLegend = ({
  chartDataItems,
  fractionDigits = 0,
}: {
  chartDataItems: ChartDataItem[];
  fractionDigits?: number;
}) => {
  return (
    <div className="flex w-full flex-col">
      {chartDataItems.map((chartData) => (
        <div key={chartData.name}>
          <DashboardChartDataItem
            text={chartData.name}
            value={chartData.value}
            color={chartData.color}
            fractionDigits={fractionDigits}
          />
          <div className="mb-3 mt-3">
            <VerticalDivider />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChartLegend;
