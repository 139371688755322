import { useEffect } from "react";
import { TotalRewardData } from "../model";
import { ProgressState } from "../utils/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
  getTotalRewardData,
  resetTotalRewardState,
  TotalRewardState,
} from "../store/totalRewardSlice";

export interface TotalRewardHook {
  data: TotalRewardData | null;
  loading: ProgressState;
  error: string | null;
  reset: () => void;
}

export const useTotalReward = (): TotalRewardHook => {
  const dispatch = useDispatch();

  const { data, loading, error } = useSelector<RootState, TotalRewardState>(
    (state) => state.totalRewardData,
  );

  const resetTotalRewardData = () => {
    dispatch(resetTotalRewardState());
  };

  useEffect(() => {
    dispatch(getTotalRewardData() as any);
  }, [dispatch]);

  return {
    data,
    loading,
    error,
    reset: resetTotalRewardData,
  };
};
