import { isMobile } from "react-device-detect";
import { ChartDataItem } from "../../../model";
import Button from "../../Button";
import ChartLegend from "./ChartLegend";
import DonutPieChart from "./DonutPieChart";
import classNames from "classnames"; // Import classnames
import { Drawer } from "vaul";
import BigNumber from "bignumber.js";

interface DashboardChartProps {
  title: string;
  actionTitle?: string;
  action?: () => void;
  chartDataItems: ChartDataItem[];
  blur?: boolean; // Add the blur prop
  totalTitle: string;
  totalValue: BigNumber;
  isWalletConnected?: boolean;
  fractionDigits?: number;
}

const DashboardChartHeader = ({
  title,
  actionTitle,
  action,
  isWalletConnected,
}: {
  title: string;
  actionTitle?: string;
  action?: () => void;
  isWalletConnected?: boolean;
}) => {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-y-space-11 lg:flex-row lg:justify-between">
      <p className="title-xl-medium-light">{title}</p>
      <div>
        {((!isMobile && actionTitle) || (isMobile && isWalletConnected)) && (
          <Button text={actionTitle} onClick={action} />
        )}
        {isMobile && actionTitle && !isWalletConnected && (
          <Drawer.Trigger asChild>
            <div>
              <Button text={actionTitle} />
            </div>
          </Drawer.Trigger>
        )}
      </div>
    </div>
  );
};

const DashboardChart = ({
  title,
  actionTitle,
  action,
  chartDataItems,
  blur,
  totalTitle,
  totalValue,
  isWalletConnected,
  fractionDigits = 0,
}: DashboardChartProps) => {
  return (
    <div className="flex h-full w-full flex-col items-start gap-y-space-11 p-space-6">
      <DashboardChartHeader
        title={title}
        actionTitle={actionTitle}
        action={action}
        isWalletConnected={isWalletConnected}
      />
      <div className="relative h-full w-full">
        {blur && (
          <div className="z-1 absolute inset-0 flex items-center justify-center">
            <div className="flex flex-col items-center">
              <p className="title-xl-medium-light text-center">
                Please connect your wallet <br></br> to see your $BOON portfolio
              </p>
            </div>
          </div>
        )}
        <div
          className={classNames("z-10 h-full w-full", { "blur-[6px]": blur })}
        >
          <div className="grid grid-cols-1 gap-y-8 lg:grid-cols-[1fr,2fr] lg:gap-x-8">
            <DonutPieChart
              chartDataItems={chartDataItems}
              mainTitle={totalTitle}
              mainValue={totalValue}
              fractionDigits={fractionDigits}
            />
            <ChartLegend
              chartDataItems={chartDataItems}
              fractionDigits={fractionDigits}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardChart;
