import _ from "lodash";
import KeplrWallet from "./keplr";
import { useDispatch, useSelector } from "react-redux";
import {
  setWalletInfo,
  disconnect as disconnectWallet,
  setLoading,
} from "../../store/walletSlice";
import { RootState } from "../../store";
import SonarWallet from "./sonar";
import {
  handleKeplrErrors,
  handleSonarErrors,
  WalletTransactionProcessingError,
} from "./errors";
import { ToastType } from "../../utils";
import { setMessage } from "../../store/notificationsSlice";
import {
  setClaimProgress,
  setStakeProgress,
} from "../../store/tokenManagementSlice";
import BigNumber from "bignumber.js";
import { toUtf8 } from "@cosmjs/encoding";
import {
  DAO_REWARDS_ADDRESS,
  DAO_VOTING_ADDRESS,
  MDAO_VESTING_ADDRESS,
  RPC_URL,
  TOKEN_ADDRESS,
} from "../../utils/constants";
import { coin } from "@cosmjs/proto-signing";
import { resetUserBalanceState } from "../../store/userBalanceSlice";
import { resetUserStakedAmountState } from "../../store/userStakedAmountSlice";
import { resetUserUnstakedAmountState } from "../../store/userUnstakedAmountSlice";
import { resetUserRewardsState } from "../../store/userRewardsSlice";
import { resetTotalRewardState } from "../../store/totalRewardSlice";
import { useWalletContext } from "../../store/context/WalletContext";
import { CwVestingFactoryQueryClient } from "../../model/generated/CwVestingFactory.client";
import { CwVestingQueryClient } from "../../model/generated/CwVesting.client";
import { CosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { resetUserVestingState, UserVestingState } from "../../store/userVestingSlice";

export enum Wallet {
  Sonar,
  Keplr,
}

export interface WalletHook {
  connect: (wallet: Wallet, onConnect?: () => void) => void;
  disconnect: () => void;
  stake: (amount: BigNumber) => void;
  unstake: (amount: BigNumber) => void;
  claim: (
    rewardsAmount: BigNumber,
    claimableUnstakingAmount: BigNumber,
  ) => void;
  walletAddress: string | null;
  isWalletConnected: boolean;
}

const useWallet = (): WalletHook => {
  const dispatch = useDispatch();
  const walletAddress = useSelector(
    (state: RootState) => state.walletData.walletAddress,
  );
  const { client, setClient: setBoonActionClient } = useWalletContext();
  const { data: vestingData } = useSelector<RootState, UserVestingState>(
    (state) => state.userVestingData
  );

  const isWalletConnected = !_.isNil(walletAddress);

  const connect = async (wallet: Wallet, onConnect?: () => void) => {
    dispatch(setLoading("IN_PROGRESS"));
    if (wallet === Wallet.Keplr) {
      try {
        const { client, walletAddress } = await KeplrWallet.connect();
        dispatch(setWalletInfo({ walletAddress }));
        setBoonActionClient(client);
        dispatch(
          setMessage({
            content: "Connection was successful",
            type: ToastType.Success,
          }),
        );
        onConnect?.();
        dispatch(setLoading("SUCCESS"));
      } catch (err: unknown) {
        dispatch(setLoading("FAILED"));
        handleKeplrErrors(err, (message) =>
          dispatch(setMessage({ content: message, type: ToastType.Error })),
        );
      }
    } else if (wallet === Wallet.Sonar) {
      try {
        const { client, walletAddress } = await SonarWallet.connect();
        dispatch(setWalletInfo({ walletAddress }));
        setBoonActionClient(client);
        onConnect?.();
        dispatch(
          setMessage({
            content: "Connection was successful",
            type: ToastType.Success,
          }),
        );
        dispatch(setLoading("SUCCESS"));
      } catch (err: unknown) {
        dispatch(setLoading("FAILED"));
        handleSonarErrors(err, (message) =>
          dispatch(setMessage({ content: message, type: ToastType.Error })),
        );
      }
    }
  };

  const disconnect = async () => {
    dispatch(resetUserBalanceState());
    dispatch(resetUserStakedAmountState());
    dispatch(resetUserUnstakedAmountState());
    dispatch(resetUserRewardsState());
    dispatch(resetTotalRewardState());
    dispatch(resetUserVestingState());
    dispatch(disconnectWallet());
  };

  const stake = async (amount: BigNumber) => {
    try {
      if (!client || !walletAddress) {
        console.log("Wallet not connected");
        return;
      }

      const stakeMemo = `Stake ${amount}`;

      dispatch(
        setStakeProgress({
          stakeProgress: "IN_PROGRESS",
        }),
      );

      const stakeMsg = createStakeMsg(
        DAO_VOTING_ADDRESS,
        walletAddress,
        amount,
        TOKEN_ADDRESS,
      );

      await client.signAndSendTx(walletAddress, [stakeMsg], "auto", stakeMemo);

      dispatch(
        setStakeProgress({
          stakeProgress: "SUCCESS",
          message: "Stake was successful",
        }),
      );
    } catch (err: unknown) {
      if (err instanceof WalletTransactionProcessingError) {
        dispatch(
          setStakeProgress({
            stakeProgress: "FAILED",
            message: WalletTransactionProcessingError.message,
          }),
        );
      } else {
        dispatch(
          setStakeProgress({
            stakeProgress: "FAILED",
            message: "Something unexpected happened, please try again later.",
          }),
        );
      }
    }
  };

  const unstake = async (amount: BigNumber) => {
    try {
      if (!client || !walletAddress) {
        console.log("Wallet not connected");
        return;
      }

      const unstakeMemo = `Unstake ${amount}`;
      dispatch(setStakeProgress({ stakeProgress: "IN_PROGRESS" }));

      const unstakeMsg = createUnstakeMsg(
        DAO_VOTING_ADDRESS,
        walletAddress,
        amount,
      );
      await client.signAndSendTx(
        walletAddress,
        [unstakeMsg],
        "auto",
        unstakeMemo,
      );

      dispatch(
        setStakeProgress({
          stakeProgress: "SUCCESS",
          message: "Unstake was successful",
        }),
      );
    } catch (err: unknown) {
      if (err instanceof WalletTransactionProcessingError) {
        dispatch(
          setStakeProgress({
            stakeProgress: "FAILED",
            message: WalletTransactionProcessingError.message,
          }),
        );
      } else {
        dispatch(
          setStakeProgress({
            stakeProgress: "FAILED",
            message: "Something unexpected happened, please try again later.",
          }),
        );
      }
    }
  };

  const claim = async (
    rewardsAmount: BigNumber,
    claimableUnstakingAmount: BigNumber,
  ) => {
    try {
      if (!client || !walletAddress) {
        console.log("Wallet not connected");
        return;
      }
      dispatch(setClaimProgress({ claimProgress: "IN_PROGRESS" }));

      const msgs = await createClaimMsgs(
        walletAddress,
        rewardsAmount,
        claimableUnstakingAmount,
        vestingData?.claimableAmount || new BigNumber(0),
      );
      await client.signAndSendTx(
        walletAddress,
        msgs,
        "auto",
        `Claim reward ${rewardsAmount}, unstaked amount: ${claimableUnstakingAmount}, vesting amount: ${vestingData?.claimableAmount}`,
      );

      dispatch(
        setClaimProgress({
          claimProgress: "SUCCESS",
          message: "Claim transaction was successful",
        }),
      );
    } catch (err: unknown) {
      dispatch(setClaimProgress("FAILED"));
      if (err instanceof WalletTransactionProcessingError) {
        dispatch(
          setClaimProgress({
            claimProgress: "FAILED",
            message: WalletTransactionProcessingError.message,
          }),
        );
      } else {
        dispatch(
          setStakeProgress({
            stakeProgress: "FAILED",
            message: "Something unexpected happened, please try again later.",
          }),
        );
      }
    }
  };

  const createStakeMsg = (
    voting_module: string,
    walletAddress: string,
    amount: BigNumber,
    denom: string,
  ) => {
    return {
      typeUrl: "/cosmwasm.wasm.v1.MsgExecuteContract",
      value: {
        sender: walletAddress,
        contract: voting_module,
        msg: toUtf8(JSON.stringify({ stake: {} })),
        funds: [coin(amount.toString(), denom)],
      },
    };
  };

  const createUnstakeMsg = (
    voting_module: string,
    walletAddress: string,
    amount: BigNumber,
  ) => {
    return {
      typeUrl: "/cosmwasm.wasm.v1.MsgExecuteContract",
      value: {
        sender: walletAddress,
        contract: voting_module,
        msg: toUtf8(JSON.stringify({ unstake: { amount: amount.toString() } })),
        funds: [],
      },
    };
  };

  const createClaimMsgs = async (
    walletAddress: string,
    rewardsAmount: BigNumber,
    claimableUnstakingAmount: BigNumber,
    claimableVestingAmount: BigNumber,
  ) => {
    const msgs = [];
    if (claimableUnstakingAmount.gt(0)) {
      msgs.push({
        typeUrl: "/cosmwasm.wasm.v1.MsgExecuteContract",
        value: {
          sender: walletAddress,
          contract: DAO_VOTING_ADDRESS,
          msg: toUtf8(JSON.stringify({ claim: {} })),
          funds: [],
        },
      });
    }
    if (rewardsAmount.gt(0)) {
      msgs.push({
        typeUrl: "/cosmwasm.wasm.v1.MsgExecuteContract",
        value: {
          sender: walletAddress,
          contract: DAO_REWARDS_ADDRESS,
          msg: toUtf8(JSON.stringify({ claim_rewards: {} })),
          funds: [],
        },
      });
    }
    if (claimableVestingAmount.gt(0)) {
      const client = await CosmWasmClient.connect(RPC_URL);
      const vestingFactoryQueryClient = new CwVestingFactoryQueryClient(client, MDAO_VESTING_ADDRESS);
      const schedules = await vestingFactoryQueryClient.listVestingContractsByRecipient({ recipient: walletAddress });
      for (const s of schedules) {
        const vestingQueryClient = new CwVestingQueryClient(client, s.contract);
        const claimable = await vestingQueryClient.distributable({});
        if (new BigNumber(claimable).gt(0)) {
          msgs.push({
            typeUrl: "/cosmwasm.wasm.v1.MsgExecuteContract",
            value: {
              sender: walletAddress,
              contract: s.contract,
              msg: toUtf8(JSON.stringify({ distribute: {} })),
              funds: [],
            },
          });
        }
      }
    }
    return msgs;
  };

  // const createSendAmountMsg = (walletAddress: string, amount: BigNumber, denom: string, toAddress: string) => {
  //   return {
  //     typeUrl: "/cosmos.bank.v1beta1.MsgSend",
  //     value: {
  //       fromAddress: walletAddress,
  //       toAddress: toAddress,
  //       amount: [{ denom, amount: amount.toString() }],
  //     },
  //   };
  // }

  return {
    connect,
    disconnect,
    stake,
    unstake,
    claim,
    walletAddress,
    isWalletConnected,
  };
};

export default useWallet;
