import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProgressState } from "../utils/types";
import { TotalSupplyData } from "../model";
import { TOKEN_ADDRESS } from "../utils/constants";
import { initializeQueryClient } from "../utils/queryClient";
import BigNumber from "bignumber.js";

export interface TotalSupplyState {
  data: TotalSupplyData | null;
  loading: ProgressState;
  error: string | null;
}

const initialState: TotalSupplyState = {
  data: null,
  loading: "IDLE",
  error: null,
};

export const getTotalSupplyData = createAsyncThunk(
  "total/supply",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const queryClient = await initializeQueryClient();
      const supplyData = await queryClient?.bank.supplyOf(TOKEN_ADDRESS);
      const supply = supplyData ? new BigNumber(supplyData.amount) : new BigNumber(0);
      return { amount: supply };
    } catch (err) {
      console.log(err);
      dispatch(resetTotalSupplyState());
      return rejectWithValue({ message: "Failed to fetch total supply" });
    }
  },
);

export const totalSupplySlice = createSlice({
  name: "totalSupply",
  initialState,
  reducers: {
    resetTotalSupplyState: (state) => {
      state.loading = "IDLE";
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(getTotalSupplyData.pending, (state: TotalSupplyState) => {
        state.loading = "IN_PROGRESS";
      })
      .addCase(
        getTotalSupplyData.fulfilled,
        (state: TotalSupplyState, action: any) => {
          state.loading = "SUCCESS";
          state.data = action.payload;
        },
      )
      .addCase(
        getTotalSupplyData.rejected,
        (state: TotalSupplyState, action: any) => {
          state.data = null;
          state.loading = "FAILED";
          state.error = action.error.message || "Failed fetch total supply";
        },
      );
  },
});

export const { resetTotalSupplyState } = totalSupplySlice.actions;

export default totalSupplySlice.reducer;
