import { useTokenPrice } from "../../../hooks/useTokenPrice";
import DashboardHeaderItem from "./DashboardHeaderItem";
import { useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import { useTotalSupply } from "../../../hooks/useTotalSupply";
import { divideAndFormatBoon, formatBoon } from "../../../utils";
import { useTotalStaked } from "../../../hooks/useTotalStaked";
import { useTotalReward } from "../../../hooks/useTotalReward";
import { useTotalVesting } from "../../../hooks/useTotalVesting";

const DashboardHeader = () => {
  const [visibleData, setVisibleData] = useState({
    liquidAmount: new BigNumber(0),
    fdvAmount: new BigNumber(0),
    tokenPrice: new BigNumber(0),
  });

  const { data: tokenData } = useTokenPrice();

  const { data: supplyData } = useTotalSupply();

  const { data: stakedData, loading: stakedLoading } = useTotalStaked();

  const { data: rewardData, loading: rewardLoading } = useTotalReward();

  const { data: vestingData, loading: vestingLoading } = useTotalVesting();

  useEffect(() => {
    if (
      stakedLoading === "SUCCESS" &&
      rewardLoading === "SUCCESS" &&
      vestingLoading === "SUCCESS" &&
      stakedData &&
      stakedData.amount &&
      supplyData &&
      supplyData.amount &&
      vestingData &&
      vestingData.claimableAmount &&
      vestingData.nonClaimableAmount &&
      rewardData &&
      rewardData.amount
    ) {
      const totalSupply = supplyData.amount
        ? new BigNumber(supplyData.amount)
        : new BigNumber(0);
      const totalStaked = stakedData.amount
        ? new BigNumber(stakedData.amount)
        : new BigNumber(0);
      const totalVesting = vestingData.nonClaimableAmount
        ? new BigNumber(vestingData.nonClaimableAmount)
        : new BigNumber(0);
      const totalRewards = rewardData.amount
        ? new BigNumber(rewardData.amount)
        : new BigNumber(0);
      const liquidAmount = totalSupply
        .minus(totalStaked)
        .minus(totalVesting)
        .minus(totalRewards);

      setVisibleData({
        liquidAmount,
        fdvAmount: new BigNumber(0),
        tokenPrice: new BigNumber(0),
      });
    }
  }, [
    tokenData,
    stakedLoading,
    stakedData,
    supplyData,
    vestingLoading,
    vestingData,
    rewardLoading,
    rewardData,
  ]);

  useEffect(() => {
    if (supplyData && supplyData.amount && tokenData && tokenData.price) {
      const fdvAmount = supplyData.amount.times(tokenData.price);

      const price = tokenData.price ? tokenData.price : 0;

      setVisibleData((prevData) => ({
        ...prevData,
        fdvAmount,
        tokenPrice: new BigNumber(price),
      }));
    }
  }, [
    tokenData,
    stakedLoading,
    stakedData,
    supplyData,
    vestingLoading,
    vestingData,
    rewardLoading,
    rewardData,
  ]);

  return (
    <div className="flex flex-col items-start justify-center md:flex-row md:items-center">
      <DashboardHeaderItem
        formattedText={`$ ${visibleData.tokenPrice.toFixed(3)}`}
        rawText={`$ ${formatBoon(visibleData.tokenPrice, 10)}`}
        description="$BOON Price"
      />
      <div className="mx-space-2 my-space-1 h-full w-[1px] bg-surface-700 md:mx-space-11"></div>
      <DashboardHeaderItem
        formattedText={`$ ${divideAndFormatBoon(visibleData.fdvAmount)}`}
        rawText={`$ ${divideAndFormatBoon(visibleData.fdvAmount, 10)}`}
        description="Fully Diluted Value"
      />
      <div className="mx-space-2 my-space-1 h-full w-[1px] bg-surface-700 md:mx-space-11"></div>
      <DashboardHeaderItem
        formattedText={divideAndFormatBoon(visibleData.liquidAmount)}
        rawText={divideAndFormatBoon(visibleData.liquidAmount, 10)}
        description="Liquid Supply"
      />
    </div>
  );
};

export default DashboardHeader;
