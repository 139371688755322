import { useEffect } from "react";
import { ProgressState } from "../utils/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
  getUserRewardsData,
  resetUserRewardsState,
  UserRewardsState,
} from "../store/userRewardsSlice";
import { UserRewardData } from "../model";
import useWallet from "./wallet/useWallet";

export interface UserRewardsHook {
  data: UserRewardData | null;
  loading: ProgressState;
  error: string | null;
  reset: () => void;
}

export const useUserRewards = (): UserRewardsHook => {
  const { walletAddress } = useWallet();
  const dispatch = useDispatch();

  const { data, loading, error } = useSelector<RootState, UserRewardsState>(
    (state) => state.userRewardsData,
  );

  const resetUserRewardsData = () => {
    dispatch(resetUserRewardsState());
  };

  useEffect(() => {
    if (!walletAddress) return;

    dispatch(getUserRewardsData(walletAddress) as any);
  }, [dispatch, walletAddress]);

  return {
    data,
    loading,
    error,
    reset: resetUserRewardsData,
  };
};
