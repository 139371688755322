import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProgressState } from "../utils/types";
import { RPC_URL, DAO_ADDRESS } from "../utils/constants";
import { DaoDaoCoreQueryClient } from "../model/generated/DaoDaoCore.client";
import { DaoVotingTokenStakedQueryClient } from "../model/generated/DaoVotingTokenStaked.client";
import { ListStakersResponse } from "../model/generated/DaoVotingTokenStaked.types";
import { CosmWasmClient } from "@cosmjs/cosmwasm-stargate";

export interface TotalStakersState {
  data: ListStakersResponse | null;
  loading: ProgressState;
  error: string | null;
}

const initialState: TotalStakersState = {
  data: null,
  loading: "IDLE",
  error: null,
};

export const getTotalStakersData = createAsyncThunk(
  "total/stakers",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const client = await CosmWasmClient.connect(RPC_URL);

      if (!client) {
        return rejectWithValue({
          message: "Wallet client not available, Please try again later",
        });
      }

      const voting_module = await new DaoDaoCoreQueryClient(
        client,
        DAO_ADDRESS,
      ).votingModule();
      const stakingQueryClient = new DaoVotingTokenStakedQueryClient(
        client,
        voting_module,
      );
      const stakers = await stakingQueryClient.listStakers({});

      return stakers;
    } catch (err) {
      console.log(err);
      dispatch(resetTotalStakersState());
      return rejectWithValue({ message: "Failed to fetch stakers" });
    }
  },
);

export const totalStakersSlice = createSlice({
  name: "totalStakers",
  initialState,
  reducers: {
    resetTotalStakersState: (state) => {
      state.loading = "IDLE";
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(getTotalStakersData.pending, (state: TotalStakersState) => {
        state.loading = "IN_PROGRESS";
      })
      .addCase(
        getTotalStakersData.fulfilled,
        (state: TotalStakersState, action: any) => {
          state.loading = "SUCCESS";
          state.data = action.payload;
        },
      )
      .addCase(
        getTotalStakersData.rejected,
        (state: TotalStakersState, action: any) => {
          state.data = null;
          state.loading = "FAILED";
          state.error = action.error.message || "Failed fetch stakers";
        },
      );
  },
});

export const { resetTotalStakersState } = totalStakersSlice.actions;

export default totalStakersSlice.reducer;
