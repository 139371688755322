import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProgressState } from "../utils/types";
import {
  TOKEN_ADDRESS,
} from "../utils/constants";
import { UserBalanceData } from "../model";
import { initializeQueryClient } from "../utils/queryClient";
import BigNumber from "bignumber.js";

export interface UserBalanceState {
  data: UserBalanceData | null;
  loading: ProgressState;
  error: string | null;
}

const initialState: UserBalanceState = {
  data: null,
  loading: "IDLE",
  error: null,
};

export const getUserBalanceData = createAsyncThunk(
  "user/balance",
  async (wallet_address: string, { rejectWithValue, dispatch }) => {
    try {
      const queryClient = await initializeQueryClient();
      
      const balance = await queryClient.bank.balance(wallet_address, TOKEN_ADDRESS);
      
      return { amount: new BigNumber(balance.amount) };
    } catch (err) {
      console.log(err);
      dispatch(resetUserBalanceState());
      return rejectWithValue({
        message: "Failed to fetch user balance amount",
      });
    }
  },
);

export const userBalanceSlice = createSlice({
  name: "userBalance",
  initialState,
  reducers: {
    resetUserBalanceState: (state) => {
      state.loading = "IDLE";
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(getUserBalanceData.pending, (state: UserBalanceState) => {
        state.loading = "IN_PROGRESS";
      })
      .addCase(
        getUserBalanceData.fulfilled,
        (state: UserBalanceState, action: any) => {
          state.loading = "SUCCESS";
          state.data = action.payload;
        },
      )
      .addCase(
        getUserBalanceData.rejected,
        (state: UserBalanceState, action: any) => {
          state.data = null;
          state.loading = "FAILED";
          state.error =
            action.error.message || "Failed to fetch user balance amount";
        },
      );
  },
});

export const { resetUserBalanceState } = userBalanceSlice.actions;

export default userBalanceSlice.reducer;
