import DashboardCharts from "../components/Dashboard/Charts";
import DashboardHeader from "../components/Dashboard/Header";

const Dashboard = () => {
  return (
    <div className="flex justify-center px-space-5 py-space-11 lg:px-space-14">
      <div className="flex flex-col items-center justify-center rounded-[8px] border border-surface-800 bg-surface-900 px-space-6 py-space-6 lg:p-space-8">
        <div className="flex w-full flex-col items-center">
          <DashboardHeader />
          <div className="my-space-7 h-[1px] bg-surface-800"></div>
        </div>
        <div className="flex w-full flex-col items-center justify-center">
          <div className="flex w-full justify-start">
            <p className="title-xl-regular-dark mb-space-6">Statistics</p>
          </div>
          <div className="flex h-full w-full items-center justify-center">
            <DashboardCharts />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
