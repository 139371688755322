import { createSlice } from "@reduxjs/toolkit";
import { ProgressState } from "../utils/types";

export interface WalletState {
  walletAddress: string | null;
  loading: ProgressState;
}

const initialState: WalletState = {
  walletAddress: null,
  loading: "IDLE",
};

export const walletSlice = createSlice({
  name: "walletSlice",
  initialState,
  reducers: {
    setWalletInfo: (state, action) => {
      state.walletAddress = action.payload.walletAddress;
    },
    disconnect: (state) => {
      state.walletAddress = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setWalletInfo, disconnect, setLoading } = walletSlice.actions;

export default walletSlice.reducer;
