import { GENERAL_KEPLR_CONNECTION_ERROR_MESSAGE } from "./keplr";
import { GENERAL_SONAR_CONNECTION_ERROR_MESSAGE } from "./sonar";

export class NoWalletExistsError extends Error {
  public static message = "Please install wallet's browser extension";
}

export class NoWalletPermissionGivenError extends Error {
  public static message = "Please enable wallet's browser extension";
}

export class WalletTransactionProcessingError extends Error {
  public static message = "Please try processing transaction from wallet later";
}

export class NoAccountFoundError extends Error {
  public static message =
    "No account with specified address was found on blockchain";
}

export const handleKeplrErrors = (
  err: unknown,
  errorHandler: (message: string) => void,
) => {
  if (err instanceof NoWalletExistsError) {
    errorHandler(NoWalletExistsError.message);
  } else if (err instanceof NoWalletPermissionGivenError) {
    errorHandler(NoWalletPermissionGivenError.message);
  } else {
    errorHandler(GENERAL_KEPLR_CONNECTION_ERROR_MESSAGE);
  }
};

export const handleSonarErrors = (
  err: unknown,
  errorHandler: (message: string) => void,
) => {
  if (err instanceof NoAccountFoundError) {
    errorHandler(NoAccountFoundError.message);
  } else {
    errorHandler(GENERAL_SONAR_CONNECTION_ERROR_MESSAGE);
  }
};
