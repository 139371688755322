import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProgressState } from "../utils/types";
import { CosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import BigNumber from "bignumber.js";
import { CwVestingFactoryQueryClient } from "../model/generated/CwVestingFactory.client";
import { CwVestingQueryClient } from "../model/generated/CwVesting.client";
import { MDAO_ADDRESS, MDAO_VESTING_ADDRESS, RPC_URL, TOKEN_ADDRESS } from "../utils/constants";
import { initializeQueryClient } from "../utils/queryClient";
import { VestingAmountData } from "../model";

export interface TotalVestingState {
  data: VestingAmountData | null;
  loading: ProgressState;
  error: string | null;
}

const initialState: TotalVestingState = {
  data: null,
  loading: "IDLE",
  error: null,
};

export const getTotalVestingData = createAsyncThunk(
  "total/vesting",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const client = await CosmWasmClient.connect(RPC_URL);
      const queryClient = await initializeQueryClient();
      const vestingFactoryQueryClient = new CwVestingFactoryQueryClient(client, MDAO_VESTING_ADDRESS);
      const schedules = await vestingFactoryQueryClient.listVestingContractsByInstantiator({ instantiator: MDAO_ADDRESS });
      
      let nonClaimable = new BigNumber(0);
      let claimable = new BigNumber(0);

      for (const s of schedules) {
        const balance = await queryClient.bank.balance(s.contract, TOKEN_ADDRESS);
        if (balance.amount === "0") {
          continue;
        }
        const vestingQueryClient = new CwVestingQueryClient(client, s.contract);
        const claimableAmount = await vestingQueryClient.distributable({});
        nonClaimable = nonClaimable.plus(new BigNumber(balance.amount).minus(new BigNumber(claimableAmount)));
        claimable = claimable.plus(new BigNumber(claimableAmount));
      }

      return {
        nonClaimableAmount: nonClaimable,
        claimableAmount: claimable,
      };
    } catch (err) {
      console.log(err);
      dispatch(resetTotalVestingState());
      return rejectWithValue({
        message: "Failed to fetch total vesting amounts",
      });
    }
  },
);

export const totalVestingSlice = createSlice({
  name: "totalVesting",
  initialState,
  reducers: {
    resetTotalVestingState: (state) => {
      state.loading = "IDLE";
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(getTotalVestingData.pending, (state: TotalVestingState) => {
        state.loading = "IN_PROGRESS";
      })
      .addCase(
        getTotalVestingData.fulfilled,
        (state: TotalVestingState, action: any) => {
          state.loading = "SUCCESS";
          state.data = action.payload;
        },
      )
      .addCase(
        getTotalVestingData.rejected,
        (state: TotalVestingState, action: any) => {
          state.data = null;
          state.loading = "FAILED";
          state.error =
            action.error.message || "Failed to fetch total vesting amounts";
        },
      );
  },
});

export const { resetTotalVestingState } = totalVestingSlice.actions;

export default totalVestingSlice.reducer;
