import GlobalDashboardChart from "./GlobalDashboardChart";
import PersonalDashboardChart from "./PersonalDashboardChart";

const DashboardCharts = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-y-space-6 lg:flex-row lg:gap-x-space-6">
      <div className="h-full w-full rounded-[8px] border border-surface-800">
        <PersonalDashboardChart />
      </div>
      <div className="h-full w-full rounded-[8px] border border-surface-800">
        <GlobalDashboardChart />
      </div>
    </div>
  );
};

export default DashboardCharts;
