import classNames from "classnames";
import { MouseEvent } from "react";

const MenuItem = ({
  text,
  active,
  onClick,
  addonClassNames = {},
}: {
  text: string;
  active?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  addonClassNames?: Record<string, boolean>;
}) => {
  return (
    <button
      onClick={(e) => {
        onClick && onClick(e);
      }}
      className={classNames({
        "bg-primary-500": active,
        "rounded-md": true,
        "px-space-3": true,
        "py-space-2": true,
        ...addonClassNames,
      })}
    >
      <p
        className={classNames({
          "body-lg-regular-light": active,
          "body-lg-regular-dark": !active,
        })}
      >
        {text}
      </p>
    </button>
  );
};

export default MenuItem;
