interface StakingDetailProps {
  text: string;
  value: string;
}

const StakingDetail = ({ text, value }: StakingDetailProps) => {
  return (
    <div className="flex w-full justify-between">
      <p className="body-md-regular-dark">
        {text}
      </p>{" "}
      <p className="body-md-regular-light">
        {value} $BOON
      </p>
    </div>
  );
};

export default StakingDetail;
