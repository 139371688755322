import { ChangeEvent, useEffect, useState } from "react";
import useWallet from "../../hooks/wallet/useWallet";
import { useUserBalance } from "../../hooks/useUserBalance";
import { useUserStakedAmount } from "../../hooks/useUserTotalStakeAmount";
import StakingInfoHeader from "./StakingInfoHeader";
import StakingInput from "./StakingInput";
import StakingDetail from "./StakingDetail";
import Button from "../Button";
import { divideAndFormatBoon, ToastType } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { getUserStakedAmountData } from "../../store/userStakedAmountSlice";
import { getUserBalanceData } from "../../store/userBalanceSlice";
import { getUserUnstakedAmountData } from "../../store/userUnstakedAmountSlice";
import { getUserRewardsData } from "../../store/userRewardsSlice";
import BoonModal from "../Modal";
import WalletsPopover from "../Navigation/WalletsPopover";
import { isMobile } from "react-device-detect";
import { Drawer } from "vaul";
import { RootState } from "../../store";
import { setMessage } from "../../store/notificationsSlice";
import { setStakeProgress } from "../../store/tokenManagementSlice";
import { TailSpin } from "react-loader-spinner";
import BigNumber from "bignumber.js";

const StakeUnstake = () => {
  const { isWalletConnected, walletAddress, stake, unstake } = useWallet();
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: userBalanceData } = useUserBalance();

  const { data: userStakedData } = useUserStakedAmount();
  const { stakeProgress, message: stakeMessage } = useSelector(
    (state: RootState) => state.tokenManagementData,
  );

  useEffect(() => {
    if (walletAddress) {
      if (stakeProgress === "SUCCESS") {
        setInputValue("");
        dispatch(getUserBalanceData(walletAddress) as any);
        dispatch(getUserStakedAmountData(walletAddress) as any);
        dispatch(getUserUnstakedAmountData(walletAddress) as any);
        dispatch(getUserRewardsData(walletAddress) as any);
        dispatch(
          setMessage({
            content: stakeMessage,
            type: ToastType.Success,
          }),
        );
        dispatch(setStakeProgress({ progress: "IDLE", message: null }));
      } else if (stakeProgress === "FAILED") {
        dispatch(
          setMessage({
            content: stakeMessage,
            type: ToastType.Error,
          }),
        );
        dispatch(setStakeProgress({ progress: "IDLE", message: null }));
      }
    }
  }, [stakeProgress, dispatch, walletAddress, stakeMessage]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const stakeAction = () => {
    const inputAmount = new BigNumber(inputValue).mul(new BigNumber(10).pow(6));
    if (inputAmount.isNegative() || inputAmount.greaterThan(userBalanceNum)) {
      dispatch(
        setMessage({
          content: "Invalid stake amount",
          type: ToastType.Error,
        }),
      );
    } else {
      stake(inputAmount);
    }
  };

  const unstakeAction = () => {
    const inputAmount = new BigNumber(inputValue).mul(new BigNumber(10).pow(6));
    if (inputAmount.isNegative() || inputAmount.greaterThan(userStakedAmountNum)) {
      dispatch(
        setMessage({
          content: "Invalid unstake amount",
          type: ToastType.Error,
        }),
      );
    } else {
      unstake(inputAmount);
    }
  };

  const userStakedAmountNum = userStakedData?.amount || new BigNumber(0);
  const userStakedAmount = divideAndFormatBoon(userStakedAmountNum, 15);

  const userBalanceNum = userBalanceData?.amount || new BigNumber(0);
  const userBalance = divideAndFormatBoon(userBalanceNum, 15);

  return (
    <div className="rounded-[8px] border border-surface-800 bg-surface-900 pb-[42px] pl-6 pr-6 pt-[42px] lg:pl-[80px] lg:pr-[80px]">
      <div className="grid grid-cols-1 gap-y-[42px]">
        <StakingInfoHeader
          title="Stake $BOON to Earn"
          subtitle="By staking $BOON, you will earn double-digit % yield in year one, with rewards released daily, and continuing to year four. $BOON has a 21-day unbonding period"
        />
        <div className="flex flex-col items-center gap-y-6">
          <StakingInput value={inputValue} onChange={handleInputChange} />
          <div className="flex w-full flex-col gap-y-4">
            <StakingDetail text="Available to stake" value={userBalance} />
            <StakingDetail
              text="Available to unstake"
              value={userStakedAmount}
            />
          </div>

          {isWalletConnected ? (
            <div className="flex w-full items-center justify-center gap-x-6">
              {stakeProgress === "IN_PROGRESS" ? (
                <TailSpin height={40} width={40} color="#9282FF" />
              ) : (
                <>
                  <Button
                    text="Stake"
                    onClick={stakeAction}
                    disabled={
                      !inputValue || userBalanceNum.lessThanOrEqualTo(0)
                    }
                  />
                  <Button
                    text="Unstake"
                    onClick={unstakeAction}
                    disabled={
                      !inputValue || userStakedAmountNum.lessThanOrEqualTo(0)
                    }
                  />
                </>
              )}
            </div>
          ) : (
            <>
              {!isMobile && (
                <Button
                  text="Connect Wallet First"
                  onClick={() => setIsModalOpen(true)}
                />
              )}
              {isMobile && (
                <Drawer.Trigger asChild>
                  <div>
                    <Button text="Connect Wallet First" />
                  </div>
                </Drawer.Trigger>
              )}
            </>
          )}
          <BoonModal
            isModalOpen={isModalOpen}
            setIsModalOpen={(val: boolean) => setIsModalOpen(val)}
          >
            <WalletsPopover onConnect={() => setIsModalOpen(false)} />
          </BoonModal>
        </div>
      </div>
    </div>
  );
};

export default StakeUnstake;
