import { useEffect } from "react";
import { TokenPriceData } from "../model";
import { ProgressState } from "../utils/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
  getTokenPriceData,
  resetTokenPriceState,
  TokenPriceState,
} from "../store/tokenPriceSlice";

export interface TokenPriceHook {
  data: TokenPriceData | null;
  loading: ProgressState;
  error: string | null;
  reset: () => void;
}

export const useTokenPrice = (): TokenPriceHook => {
  const dispatch = useDispatch();

  const { data, loading, error } = useSelector<RootState, TokenPriceState>(
    (state) => state.tokenPriceData,
  );

  const resetTokenPriceData = () => {
    dispatch(resetTokenPriceState());
  };

  useEffect(() => {
    dispatch(getTokenPriceData() as any);
  }, [dispatch]);

  return {
    data,
    loading,
    error,
    reset: resetTokenPriceData,
  };
};
