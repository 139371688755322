import { MouseEvent } from "react";

interface ButtonProps {
  text?: string;
  content?: JSX.Element;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

const Button = ({ text, onClick, content, disabled = false }: ButtonProps) => {
  return (
    <button
      disabled={disabled}
      onClick={(event) => {
        onClick && onClick(event);
      }}
      className={`w-full rounded-xl border-[1px] border-surface-800 pb-space-2 pl-space-4 pr-space-4 pt-space-2 ${
        disabled
          ? "bg-dark-400 shadow-[inset_0px_-4px_8px_#ffffff1a] cursor-not-allowed"
          : "bg-dark-400 shadow-[inset_0px_-4px_8px_#ffffff1a] hover:shadow-[inset_0px_-4px_8px_#9382ff4c]"
      }`}
    >
      {text && <p className="body-lg-medium-light">{text}</p>}
      {content}
    </button>
  );
};

export default Button;
