/**
* This file was automatically generated by @cosmwasm/ts-codegen@1.11.1.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the @cosmwasm/ts-codegen generate command to regenerate this file.
*/

import { CosmWasmClient, SigningCosmWasmClient, ExecuteResult } from "@cosmjs/cosmwasm-stargate";
import { Coin, StdFee } from "@cosmjs/amino";
import { Uint128, Timestamp, Binary, Action, StakeTrackerQuery, Vest, OwnershipForAddr, NullableUint64 } from "./CwVesting.types";
export interface CwVestingReadOnlyInterface {
  contractAddress: string;
  ownership: () => Promise<OwnershipForAddr>;
  info: () => Promise<Vest>;
  distributable: ({
    t
  }: {
    t?: Timestamp;
  }) => Promise<Uint128>;
  vested: ({
    t
  }: {
    t?: Timestamp;
  }) => Promise<Uint128>;
  totalToVest: () => Promise<Uint128>;
  vestDuration: () => Promise<NullableUint64>;
  stake: (stakeTrackerQuery: StakeTrackerQuery) => Promise<Uint128>;
}
export class CwVestingQueryClient implements CwVestingReadOnlyInterface {
  client: CosmWasmClient;
  contractAddress: string;
  constructor(client: CosmWasmClient, contractAddress: string) {
    this.client = client;
    this.contractAddress = contractAddress;
    this.ownership = this.ownership.bind(this);
    this.info = this.info.bind(this);
    this.distributable = this.distributable.bind(this);
    this.vested = this.vested.bind(this);
    this.totalToVest = this.totalToVest.bind(this);
    this.vestDuration = this.vestDuration.bind(this);
    this.stake = this.stake.bind(this);
  }
  ownership = async (): Promise<OwnershipForAddr> => {
    return this.client.queryContractSmart(this.contractAddress, {
      ownership: {}
    });
  };
  info = async (): Promise<Vest> => {
    return this.client.queryContractSmart(this.contractAddress, {
      info: {}
    });
  };
  distributable = async ({
    t
  }: {
    t?: Timestamp;
  }): Promise<Uint128> => {
    return this.client.queryContractSmart(this.contractAddress, {
      distributable: {
        t
      }
    });
  };
  vested = async ({
    t
  }: {
    t?: Timestamp;
  }): Promise<Uint128> => {
    return this.client.queryContractSmart(this.contractAddress, {
      vested: {
        t
      }
    });
  };
  totalToVest = async (): Promise<Uint128> => {
    return this.client.queryContractSmart(this.contractAddress, {
      total_to_vest: {}
    });
  };
  vestDuration = async (): Promise<NullableUint64> => {
    return this.client.queryContractSmart(this.contractAddress, {
      vest_duration: {}
    });
  };
  stake = async (stakeTrackerQuery: StakeTrackerQuery): Promise<Uint128> => {
    return this.client.queryContractSmart(this.contractAddress, {
      stake: stakeTrackerQuery
    });
  };
}
export interface CwVestingInterface extends CwVestingReadOnlyInterface {
  contractAddress: string;
  sender: string;
  receive: ({
    amount,
    msg,
    sender
  }: {
    amount: Uint128;
    msg: Binary;
    sender: string;
  }, fee?: number | StdFee | "auto", memo?: string, _funds?: Coin[]) => Promise<ExecuteResult>;
  distribute: ({
    amount
  }: {
    amount?: Uint128;
  }, fee?: number | StdFee | "auto", memo?: string, _funds?: Coin[]) => Promise<ExecuteResult>;
  cancel: (fee?: number | StdFee | "auto", memo?: string, _funds?: Coin[]) => Promise<ExecuteResult>;
  delegate: ({
    amount,
    validator
  }: {
    amount: Uint128;
    validator: string;
  }, fee?: number | StdFee | "auto", memo?: string, _funds?: Coin[]) => Promise<ExecuteResult>;
  redelegate: ({
    amount,
    dstValidator,
    srcValidator
  }: {
    amount: Uint128;
    dstValidator: string;
    srcValidator: string;
  }, fee?: number | StdFee | "auto", memo?: string, _funds?: Coin[]) => Promise<ExecuteResult>;
  undelegate: ({
    amount,
    validator
  }: {
    amount: Uint128;
    validator: string;
  }, fee?: number | StdFee | "auto", memo?: string, _funds?: Coin[]) => Promise<ExecuteResult>;
  setWithdrawAddress: ({
    address
  }: {
    address: string;
  }, fee?: number | StdFee | "auto", memo?: string, _funds?: Coin[]) => Promise<ExecuteResult>;
  withdrawDelegatorReward: ({
    validator
  }: {
    validator: string;
  }, fee?: number | StdFee | "auto", memo?: string, _funds?: Coin[]) => Promise<ExecuteResult>;
  withdrawCanceledPayment: ({
    amount
  }: {
    amount?: Uint128;
  }, fee?: number | StdFee | "auto", memo?: string, _funds?: Coin[]) => Promise<ExecuteResult>;
  registerSlash: ({
    amount,
    duringUnbonding,
    time,
    validator
  }: {
    amount: Uint128;
    duringUnbonding: boolean;
    time: Timestamp;
    validator: string;
  }, fee?: number | StdFee | "auto", memo?: string, _funds?: Coin[]) => Promise<ExecuteResult>;
  updateOwnership: (action: Action, fee?: number | StdFee | "auto", memo?: string, _funds?: Coin[]) => Promise<ExecuteResult>;
}
export class CwVestingClient extends CwVestingQueryClient implements CwVestingInterface {
  client: SigningCosmWasmClient;
  sender: string;
  contractAddress: string;
  constructor(client: SigningCosmWasmClient, sender: string, contractAddress: string) {
    super(client, contractAddress);
    this.client = client;
    this.sender = sender;
    this.contractAddress = contractAddress;
    this.receive = this.receive.bind(this);
    this.distribute = this.distribute.bind(this);
    this.cancel = this.cancel.bind(this);
    this.delegate = this.delegate.bind(this);
    this.redelegate = this.redelegate.bind(this);
    this.undelegate = this.undelegate.bind(this);
    this.setWithdrawAddress = this.setWithdrawAddress.bind(this);
    this.withdrawDelegatorReward = this.withdrawDelegatorReward.bind(this);
    this.withdrawCanceledPayment = this.withdrawCanceledPayment.bind(this);
    this.registerSlash = this.registerSlash.bind(this);
    this.updateOwnership = this.updateOwnership.bind(this);
  }
  receive = async ({
    amount,
    msg,
    sender
  }: {
    amount: Uint128;
    msg: Binary;
    sender: string;
  }, fee: number | StdFee | "auto" = "auto", memo?: string, _funds?: Coin[]): Promise<ExecuteResult> => {
    return await this.client.execute(this.sender, this.contractAddress, {
      receive: {
        amount,
        msg,
        sender
      }
    }, fee, memo, _funds);
  };
  distribute = async ({
    amount
  }: {
    amount?: Uint128;
  }, fee: number | StdFee | "auto" = "auto", memo?: string, _funds?: Coin[]): Promise<ExecuteResult> => {
    return await this.client.execute(this.sender, this.contractAddress, {
      distribute: {
        amount
      }
    }, fee, memo, _funds);
  };
  cancel = async (fee: number | StdFee | "auto" = "auto", memo?: string, _funds?: Coin[]): Promise<ExecuteResult> => {
    return await this.client.execute(this.sender, this.contractAddress, {
      cancel: {}
    }, fee, memo, _funds);
  };
  delegate = async ({
    amount,
    validator
  }: {
    amount: Uint128;
    validator: string;
  }, fee: number | StdFee | "auto" = "auto", memo?: string, _funds?: Coin[]): Promise<ExecuteResult> => {
    return await this.client.execute(this.sender, this.contractAddress, {
      delegate: {
        amount,
        validator
      }
    }, fee, memo, _funds);
  };
  redelegate = async ({
    amount,
    dstValidator,
    srcValidator
  }: {
    amount: Uint128;
    dstValidator: string;
    srcValidator: string;
  }, fee: number | StdFee | "auto" = "auto", memo?: string, _funds?: Coin[]): Promise<ExecuteResult> => {
    return await this.client.execute(this.sender, this.contractAddress, {
      redelegate: {
        amount,
        dst_validator: dstValidator,
        src_validator: srcValidator
      }
    }, fee, memo, _funds);
  };
  undelegate = async ({
    amount,
    validator
  }: {
    amount: Uint128;
    validator: string;
  }, fee: number | StdFee | "auto" = "auto", memo?: string, _funds?: Coin[]): Promise<ExecuteResult> => {
    return await this.client.execute(this.sender, this.contractAddress, {
      undelegate: {
        amount,
        validator
      }
    }, fee, memo, _funds);
  };
  setWithdrawAddress = async ({
    address
  }: {
    address: string;
  }, fee: number | StdFee | "auto" = "auto", memo?: string, _funds?: Coin[]): Promise<ExecuteResult> => {
    return await this.client.execute(this.sender, this.contractAddress, {
      set_withdraw_address: {
        address
      }
    }, fee, memo, _funds);
  };
  withdrawDelegatorReward = async ({
    validator
  }: {
    validator: string;
  }, fee: number | StdFee | "auto" = "auto", memo?: string, _funds?: Coin[]): Promise<ExecuteResult> => {
    return await this.client.execute(this.sender, this.contractAddress, {
      withdraw_delegator_reward: {
        validator
      }
    }, fee, memo, _funds);
  };
  withdrawCanceledPayment = async ({
    amount
  }: {
    amount?: Uint128;
  }, fee: number | StdFee | "auto" = "auto", memo?: string, _funds?: Coin[]): Promise<ExecuteResult> => {
    return await this.client.execute(this.sender, this.contractAddress, {
      withdraw_canceled_payment: {
        amount
      }
    }, fee, memo, _funds);
  };
  registerSlash = async ({
    amount,
    duringUnbonding,
    time,
    validator
  }: {
    amount: Uint128;
    duringUnbonding: boolean;
    time: Timestamp;
    validator: string;
  }, fee: number | StdFee | "auto" = "auto", memo?: string, _funds?: Coin[]): Promise<ExecuteResult> => {
    return await this.client.execute(this.sender, this.contractAddress, {
      register_slash: {
        amount,
        during_unbonding: duringUnbonding,
        time,
        validator
      }
    }, fee, memo, _funds);
  };
  updateOwnership = async (action: Action, fee: number | StdFee | "auto" = "auto", memo?: string, _funds?: Coin[]): Promise<ExecuteResult> => {
    return await this.client.execute(this.sender, this.contractAddress, {
      update_ownership: action
    }, fee, memo, _funds);
  };
}