import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProgressState } from "../utils/types";
import { TotalRewardData } from "../model";
import { DAO_REWARDS_ADDRESS, DAO_VOTING_ADDRESS, RPC_URL, TOKEN_ADDRESS } from "../utils/constants";
import { CosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { DaoVotingTokenStakedQueryClient } from "../model/generated/DaoVotingTokenStaked.client";
import { CwRewardsQueryClient } from "../model/generated/CwRewards.client";
import BigNumber from "bignumber.js";

export interface TotalRewardState {
  data: TotalRewardData | null;
  loading: ProgressState;
  error: string | null;
}

const initialState: TotalRewardState = {
  data: null,
  loading: "IDLE",
  error: null,
};

export const getTotalRewardData = createAsyncThunk(
  "total/reward",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const client = await CosmWasmClient.connect(RPC_URL);
      const stakingQueryClient = new DaoVotingTokenStakedQueryClient(client, DAO_VOTING_ADDRESS);
      const stakesList = await stakingQueryClient.listStakers({});
      const stakers = stakesList.stakers.map((staker) => staker.address);
    
      let rewards = new BigNumber(0);
      for (const staker in stakers) {
        const cwRewardsQueryClient = new CwRewardsQueryClient(client, DAO_REWARDS_ADDRESS);
        const pendingRewards = (await cwRewardsQueryClient.pendingRewards({staker})).rewards;
        const reward = pendingRewards.length == 0 ? new BigNumber(0) : new BigNumber(pendingRewards[0].amount);

        rewards = rewards.plus(reward);
      }

      const cwRewardsBalanceResponse = await client.getBalance(DAO_REWARDS_ADDRESS, TOKEN_ADDRESS);
      const cwRewardsBalance = new BigNumber(cwRewardsBalanceResponse.amount);

      const amount = cwRewardsBalance.minus(rewards);

      return { amount };
    } catch (err) {
      console.log(err);
      dispatch(resetTotalRewardState());
      return rejectWithValue({ message: "Failed to fetch total reward" });
    }
  },
);

export const totalRewardSlice = createSlice({
  name: "totalReward",
  initialState,
  reducers: {
    resetTotalRewardState: (state) => {
      state.loading = "IDLE";
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(getTotalRewardData.pending, (state: TotalRewardState) => {
        state.loading = "IN_PROGRESS";
      })
      .addCase(
        getTotalRewardData.fulfilled,
        (state: TotalRewardState, action: any) => {
          state.loading = "SUCCESS";
          state.data = action.payload;
        },
      )
      .addCase(
        getTotalRewardData.rejected,
        (state: TotalRewardState, action: any) => {
          state.data = null;
          state.loading = "FAILED";
          state.error = action.error.message || "Failed fetch total reward";
        },
      );
  },
});

export const { resetTotalRewardState } = totalRewardSlice.actions;

export default totalRewardSlice.reducer;