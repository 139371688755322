import {
  QueryClient,
  setupBankExtension,
  setupStakingExtension,
} from "@cosmjs/stargate";
import { Tendermint34Client } from "@cosmjs/tendermint-rpc";
import { RPC_URL } from "./constants";

export const initializeQueryClient = async () => {
  const tendermintClient = await Tendermint34Client.connect(RPC_URL);
  const queryClient = QueryClient.withExtensions(
    tendermintClient,
    setupBankExtension,
    setupStakingExtension,
  );
  return queryClient;
};
