import DashboardChart from "./DashboardChart";
import { CHART_COLOR_MAPPINGS } from "../../../utils/colors";
import { ChartItem } from "../../../utils/types";

import { useNavigate } from "react-router-dom";
import useWallet from "../../../hooks/wallet/useWallet";
import { useUserStakedAmount } from "../../../hooks/useUserTotalStakeAmount";
import { useEffect, useState } from "react";
import { ChartDataItem } from "../../../model";
import { useUserUnstakedAmount } from "../../../hooks/useUserTotalUnstakedAmount";
import { useUserBalance } from "../../../hooks/useUserBalance";
import BoonModal from "../../Modal";
import WalletsPopover from "../../Navigation/WalletsPopover";
import BigNumber from "bignumber.js";
import { useUserRewards } from "../../../hooks/useUserRewards";
import { useUserVesting } from "../../../hooks/useUserVesting";
import { VESTING_WHITELIST } from "../../../utils/constants";

export const initialChartData: ChartDataItem[] = [
  {
    name: ChartItem.Staked,
    value: new BigNumber(0),
    color: CHART_COLOR_MAPPINGS.Staked,
  },
  {
    name: ChartItem.Claimable,
    value: new BigNumber(0),
    color: CHART_COLOR_MAPPINGS.Claimable,
  },
  {
    name: ChartItem.Unstaking,
    value: new BigNumber(0),
    color: CHART_COLOR_MAPPINGS.Unstaking,
  },
  {
    name: ChartItem.Liquid,
    value: new BigNumber(0),
    color: CHART_COLOR_MAPPINGS.Liquid,
  }
];

const PersonalDashboardChart = () => {
  const navigate = useNavigate();
  const { isWalletConnected, walletAddress } = useWallet();
  const [chartData, setChartData] = useState<ChartDataItem[]>(initialChartData);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: userStakedData, loading: userStakedLoading } =
    useUserStakedAmount();

  const { data: userUnstakedData, loading: userUnstakedLoading } =
    useUserUnstakedAmount();

  const { data: userBalanceData, loading: userBalanceLoading } =
    useUserBalance();

  const { data: rewardsData, loading: rewardsLoading } = useUserRewards();

  const { data: unstakedData, loading: unstakedLoading } =
    useUserUnstakedAmount();

  const { data: userVestingData, loading: userVestingLoading } =
    useUserVesting();

  useEffect(() => {
    if (
      userStakedLoading === "SUCCESS" &&
      userStakedData &&
      rewardsLoading === "SUCCESS" &&
      rewardsData &&
      unstakedLoading === "SUCCESS" &&
      unstakedData &&
      userUnstakedLoading === "SUCCESS" &&
      userUnstakedData &&
      userBalanceLoading === "SUCCESS" &&
      userBalanceData &&
      userVestingLoading === "SUCCESS" &&
      userVestingData &&
      walletAddress
    ) {
      const stakedChartItem: ChartDataItem = {
        name: ChartItem.Staked,
        value: userStakedData.amount ? userStakedData.amount : new BigNumber(0),
        color: CHART_COLOR_MAPPINGS.Staked,
      };

      const userRewards = rewardsData.amount
        ? new BigNumber(rewardsData.amount)
        : new BigNumber(0);
      const claimableUnstake = unstakedData.claimable
        ? new BigNumber(unstakedData.claimable)
        : new BigNumber(0);
      const userClaimable = userRewards.plus(claimableUnstake);
      
      const userVestingNonClaimable = userVestingData.nonClaimableAmount
        ? new BigNumber(userVestingData.nonClaimableAmount)
        : new BigNumber(0);
      const userVestingClaimable = userVestingData.claimableAmount
        ? new BigNumber(userVestingData.claimableAmount)
        : new BigNumber(0);

      const claimableChartItem: ChartDataItem = {
        name: ChartItem.Claimable,
        value: VESTING_WHITELIST.split(',').includes(walletAddress)
          ? userClaimable.plus(userVestingClaimable)
          : userClaimable,
        color: CHART_COLOR_MAPPINGS.Claimable,
      };
      const unstakingChartItem: ChartDataItem = {
        name: ChartItem.Unstaking,
        value: userUnstakedData.not_claimable
          ? userUnstakedData.not_claimable
          : new BigNumber(0),
        color: CHART_COLOR_MAPPINGS.Unstaking,
      };
      const liquidChartItem: ChartDataItem = {
        name: ChartItem.Liquid,
        value: userBalanceData.amount
          ? userBalanceData.amount
          : new BigNumber(0),
        color: CHART_COLOR_MAPPINGS.Liquid,
      };
      const vestingChartItem: ChartDataItem = {
        name: ChartItem.Vesting,
        value: userVestingNonClaimable,
        color: CHART_COLOR_MAPPINGS.Vesting,
      };

      const chartDataItems = [
        stakedChartItem,
        claimableChartItem,
        unstakingChartItem,
        liquidChartItem,
      ];

      if (VESTING_WHITELIST.split(',').includes(walletAddress)) {
        chartDataItems.push(vestingChartItem);
      }

      setChartData(chartDataItems);
    }
  }, [
    userStakedLoading,
    userStakedData,
    rewardsLoading,
    rewardsData,
    unstakedLoading,
    unstakedData,
    userUnstakedLoading,
    userUnstakedData,
    userBalanceLoading,
    userBalanceData,
    isWalletConnected,
    userVestingLoading,
    userVestingData,
  ]);

  return (
    <>
      <DashboardChart
        isWalletConnected={isWalletConnected}
        title="My $BOON Portfolio"
        actionTitle={!isWalletConnected ? "Connect Wallet" : "Manage Portfolio"}
        action={
          !isWalletConnected
            ? () => setIsModalOpen(true)
            : () => navigate("/token_management")
        }
        blur={!isWalletConnected}
        chartDataItems={chartData}
        totalTitle="Staked $BOON"
        totalValue={chartData[0] ? chartData[0].value : new BigNumber(0)}
        fractionDigits={2}
      />
      <BoonModal
        isModalOpen={isModalOpen}
        setIsModalOpen={(val: boolean) => setIsModalOpen(val)}
      >
        <WalletsPopover onConnect={() => setIsModalOpen(false)} />
      </BoonModal>
    </>
  );
};

export default PersonalDashboardChart;
