/**
 * This file was automatically generated by @cosmwasm/ts-codegen@1.11.1.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import {
  CosmWasmClient,
  SigningCosmWasmClient,
  ExecuteResult,
} from "@cosmjs/cosmwasm-stargate";
import { StdFee } from "@cosmjs/amino";
import {
  Addr,
  Uint128,
  Coin,
  StakeChangedHookMsg,
  RewardsMsg,
  ModuleUpdateForNullable_DistributionConfig,
  ModuleUpdateForNullable_IncentiveConfig,
  ModuleUpdateForStakingConfig,
  ModuleUpdateForNullable_UnderlyingConfig,
  Schedule,
  MemberDiff,
  Config,
  IncentivesResponse,
  PendingRewardsResponse,
  StakeInfoResponse,
  ArrayOfStakeInfoResponse,
} from "./CwRewards.types";
export interface CwRewardsReadOnlyInterface {
  contractAddress: string;
  config: () => Promise<Config>;
  pendingRewards: ({
    staker,
  }: {
    staker: Addr;
  }) => Promise<PendingRewardsResponse>;
  stakeInfo: ({ staker }: { staker: Addr }) => Promise<StakeInfoResponse>;
  weights: ({
    limit,
    startAfter,
  }: {
    limit?: number;
    startAfter?: Addr;
  }) => Promise<ArrayOfStakeInfoResponse>;
  incentives: ({
    limit,
    startAfter,
  }: {
    limit?: number;
    startAfter?: Uint128;
  }) => Promise<IncentivesResponse>;
}
export class CwRewardsQueryClient implements CwRewardsReadOnlyInterface {
  client: CosmWasmClient;
  contractAddress: string;
  constructor(client: CosmWasmClient, contractAddress: string) {
    this.client = client;
    this.contractAddress = contractAddress;
    this.config = this.config.bind(this);
    this.pendingRewards = this.pendingRewards.bind(this);
    this.stakeInfo = this.stakeInfo.bind(this);
    this.weights = this.weights.bind(this);
    this.incentives = this.incentives.bind(this);
  }
  config = async (): Promise<Config> => {
    return this.client.queryContractSmart(this.contractAddress, {
      config: {},
    });
  };
  pendingRewards = async ({
    staker,
  }: {
    staker: Addr;
  }): Promise<PendingRewardsResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      pending_rewards: {
        staker,
      },
    });
  };
  stakeInfo = async ({
    staker,
  }: {
    staker: Addr;
  }): Promise<StakeInfoResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      stake_info: {
        staker,
      },
    });
  };
  weights = async ({
    limit,
    startAfter,
  }: {
    limit?: number;
    startAfter?: Addr;
  }): Promise<ArrayOfStakeInfoResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      weights: {
        limit,
        start_after: startAfter,
      },
    });
  };
  incentives = async ({
    limit,
    startAfter,
  }: {
    limit?: number;
    startAfter?: Uint128;
  }): Promise<IncentivesResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      incentives: {
        limit,
        start_after: startAfter,
      },
    });
  };
}
export interface CwRewardsInterface extends CwRewardsReadOnlyInterface {
  contractAddress: string;
  sender: string;
  updateConfig: (
    {
      distributionCfg,
      incentiveCfg,
      owner,
      stakingCfg,
      underlyingCfg,
    }: {
      distributionCfg?: ModuleUpdateForNullable_DistributionConfig;
      incentiveCfg?: ModuleUpdateForNullable_IncentiveConfig;
      owner?: Addr;
      stakingCfg?: ModuleUpdateForStakingConfig;
      underlyingCfg?: ModuleUpdateForNullable_UnderlyingConfig;
    },
    fee?: number | StdFee | "auto",
    memo?: string,
    _funds?: Coin[],
  ) => Promise<ExecuteResult>;
  addIncentive: (
    {
      denom,
      schedule,
    }: {
      denom: string;
      schedule: Schedule;
    },
    fee?: number | StdFee | "auto",
    memo?: string,
    _funds?: Coin[],
  ) => Promise<ExecuteResult>;
  stakeChangeHook: (
    stakeChangedHookMsg: StakeChangedHookMsg,
    fee?: number | StdFee | "auto",
    memo?: string,
    _funds?: Coin[],
  ) => Promise<ExecuteResult>;
  memberChangedHook: (
    {
      diffs,
    }: {
      diffs: MemberDiff[];
    },
    fee?: number | StdFee | "auto",
    memo?: string,
    _funds?: Coin[],
  ) => Promise<ExecuteResult>;
  adjustWeights: (
    {
      delta,
    }: {
      delta: Addr[][];
    },
    fee?: number | StdFee | "auto",
    memo?: string,
    _funds?: Coin[],
  ) => Promise<ExecuteResult>;
  rewards: (
    rewardsMsg: RewardsMsg,
    fee?: number | StdFee | "auto",
    memo?: string,
    _funds?: Coin[],
  ) => Promise<ExecuteResult>;
}
export class CwRewardsClient
  extends CwRewardsQueryClient
  implements CwRewardsInterface
{
  client: SigningCosmWasmClient;
  sender: string;
  contractAddress: string;
  constructor(
    client: SigningCosmWasmClient,
    sender: string,
    contractAddress: string,
  ) {
    super(client, contractAddress);
    this.client = client;
    this.sender = sender;
    this.contractAddress = contractAddress;
    this.updateConfig = this.updateConfig.bind(this);
    this.addIncentive = this.addIncentive.bind(this);
    this.stakeChangeHook = this.stakeChangeHook.bind(this);
    this.memberChangedHook = this.memberChangedHook.bind(this);
    this.adjustWeights = this.adjustWeights.bind(this);
    this.rewards = this.rewards.bind(this);
  }
  updateConfig = async (
    {
      distributionCfg,
      incentiveCfg,
      owner,
      stakingCfg,
      underlyingCfg,
    }: {
      distributionCfg?: ModuleUpdateForNullable_DistributionConfig;
      incentiveCfg?: ModuleUpdateForNullable_IncentiveConfig;
      owner?: Addr;
      stakingCfg?: ModuleUpdateForStakingConfig;
      underlyingCfg?: ModuleUpdateForNullable_UnderlyingConfig;
    },
    fee: number | StdFee | "auto" = "auto",
    memo?: string,
    _funds?: Coin[],
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        update_config: {
          distribution_cfg: distributionCfg,
          incentive_cfg: incentiveCfg,
          owner,
          staking_cfg: stakingCfg,
          underlying_cfg: underlyingCfg,
        },
      },
      fee,
      memo,
      _funds,
    );
  };
  addIncentive = async (
    {
      denom,
      schedule,
    }: {
      denom: string;
      schedule: Schedule;
    },
    fee: number | StdFee | "auto" = "auto",
    memo?: string,
    _funds?: Coin[],
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        add_incentive: {
          denom,
          schedule,
        },
      },
      fee,
      memo,
      _funds,
    );
  };
  stakeChangeHook = async (
    stakeChangedHookMsg: StakeChangedHookMsg,
    fee: number | StdFee | "auto" = "auto",
    memo?: string,
    _funds?: Coin[],
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        stake_change_hook: stakeChangedHookMsg,
      },
      fee,
      memo,
      _funds,
    );
  };
  memberChangedHook = async (
    {
      diffs,
    }: {
      diffs: MemberDiff[];
    },
    fee: number | StdFee | "auto" = "auto",
    memo?: string,
    _funds?: Coin[],
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        member_changed_hook: {
          diffs,
        },
      },
      fee,
      memo,
      _funds,
    );
  };
  adjustWeights = async (
    {
      delta,
    }: {
      delta: Addr[][];
    },
    fee: number | StdFee | "auto" = "auto",
    memo?: string,
    _funds?: Coin[],
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        adjust_weights: {
          delta,
        },
      },
      fee,
      memo,
      _funds,
    );
  };
  rewards = async (
    rewardsMsg: RewardsMsg,
    fee: number | StdFee | "auto" = "auto",
    memo?: string,
    _funds?: Coin[],
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        rewards: rewardsMsg,
      },
      fee,
      memo,
      _funds,
    );
  };
}
