import BottomSheet from "../BottomSheet";
import WalletsPopover from "./WalletsPopover";

const ConnectWalletBottomSheet = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}) => {
  return (
    <BottomSheet isOpen={isOpen} setIsOpen={setIsOpen}>
      <WalletsPopover onConnect={() => setIsOpen(false)} />
    </BottomSheet>
  );
};

export default ConnectWalletBottomSheet;
