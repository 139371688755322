import BigNumber from "bignumber.js";
import { HttpResponseError } from "./types";

export const getSingleErrorMessage = (
  errorResponse: HttpResponseError | undefined | null,
  placeholder?: string,
) => {
  if (errorResponse?.message) {
    return errorResponse.message;
  }
  if (errorResponse?.messages) errorResponse.messages?.join(",");
  return placeholder;
};

export const floorToPrecision = (
  num: number | null,
  precision: number,
): number => {
  if (!num) return 0;

  const factor = Math.pow(10, precision);
  return Math.ceil(num * factor) / factor;
};

export const roundAndFormat = (
  num: number | null,
  precision: number,
): string => {
  if (!num) return "0";

  const roundedNum = Math.round(num);
  const formattedNum = roundedNum.toFixed(precision);
  return formattedNum.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const normalizeAndRoundDenomAmount = (
  amount: number | null | undefined,
): string => {
  if (!amount) return "0";

  const normAmount = amount / Math.pow(10, 6);

  return roundAndFormat(normAmount, 0);
};

export const divideAndFormatBoon = (
  amount?: BigNumber | null,
  fractionDigits = 0,
): string => {
  try {
    if (!amount || amount?.isNaN() || amount.isZero()) return "0";
    const temp = amount.dividedBy(new BigNumber(10).pow(6));
    let formattedValue = temp.toFormat(fractionDigits);

    formattedValue = formattedValue.replace(/(\.\d*?[1-9])0+$/, '$1');
    formattedValue = formattedValue.replace(/\.0*$/, '');

    return formattedValue;
  } catch (error) {
    console.error("Error in divideAndFormatBoon:", error);
    console.log("Amount that caused error:", amount?.toString());
    return "0";
  }
};

export const formatBoon = (
  amount?: BigNumber | null,
  fractionDigits = 0,
): string => {
  try {
    if (!amount || amount?.isNaN() || amount.isZero()) return "0";
    let formattedValue = amount.toFormat(fractionDigits);

    formattedValue = formattedValue.replace(/(\.\d*?[1-9])0+$/, '$1');
    formattedValue = formattedValue.replace(/\.0*$/, '');

    return formattedValue;
  } catch (error) {
    console.error("Error in formatBoon:", error);
    console.log("Amount that caused error:", amount?.toString());
    return "0";
  }
};

export enum ToastType {
  Success = "success",
  Error = "error",
  Info = "info",
}
