import Button from "../Button";

const Fallback = () => {
  return (
    <div className="flex h-full w-full flex-1 flex-col items-center justify-center">
      <p className="title-xl-medium-light mt-space-10 text-center">
        Something unexpected happened
      </p>
      <p className="title-lg-regular text-center text-white">
        Please try again later or if the problem persists contact Boon support:
        support@boon.com
      </p>
      <div className="mt-space-9 flex flex-1">
        <Button
          text="Go to home page"
          onClick={() => (window.location.href = "/")}
        />
      </div>
    </div>
  );
};

export default Fallback;
