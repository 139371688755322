import { useEffect } from "react";
import { TotalSupplyData } from "../model";
import { ProgressState } from "../utils/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
  getTotalSupplyData,
  resetTotalSupplyState,
  TotalSupplyState,
} from "../store/totalSupplySlice";

export interface TotalSupplyHook {
  data: TotalSupplyData | null;
  loading: ProgressState;
  error: string | null;
  reset: () => void;
}

export const useTotalSupply = (): TotalSupplyHook => {
  const dispatch = useDispatch();

  const { data, loading, error } = useSelector<RootState, TotalSupplyState>(
    (state) => state.totalSupplyData,
  );

  const resetTotalSupplyData = () => {
    dispatch(resetTotalSupplyState());
  };

  useEffect(() => {
    dispatch(getTotalSupplyData() as any);
  }, [dispatch]);

  return {
    data,
    loading,
    error,
    reset: resetTotalSupplyData,
  };
};
