export const arrowDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="white"
    color="white"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.42296 13.78L2.95068 7.30769L4.03838 6.22L9.42296 11.6046L14.8076 6.22L15.8953 7.30769L9.42296 13.78Z"
      fill="white"
    />
  </svg>
);

export const circle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="white"
    color="white"
  >
    <circle cx="12" cy="12" r="12" fill="#D9D9D9" />
  </svg>
);
