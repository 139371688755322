import {
  GAS_PRICE,
  RPC_URL,
  CHAIN_ID,
  TOKEN,
  TOKEN_ADDRESS,
  TEST_NET,
} from "../../utils/constants";
import { SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import type { ChainInfo, FeeCurrency } from "@keplr-wallet/types";
import { NoWalletExistsError, NoWalletPermissionGivenError } from "./errors";
import { BoonActionClient } from "../../model";

export const GENERAL_KEPLR_CONNECTION_ERROR_MESSAGE =
  "Please try again connecting Keplr wallet later";

class KeplrWallet {
  public static async connect(chainId: string = CHAIN_ID) {
    if (!window.keplr) {
      throw new NoWalletExistsError();
    } else {
      try {
        KeplrWallet.checkTestnet();

        await window.keplr.enable(chainId);
        window.keplr.disable(chainId);
      } catch (err) {
        console.log(err);
        throw new NoWalletPermissionGivenError();
      }

      const offlineSigner = window.keplr.getOfflineSigner(chainId);

      const accounts = await offlineSigner.getAccounts();

      const client = await SigningCosmWasmClient.connectWithSigner(
        RPC_URL,
        offlineSigner,
        {
          gasPrice: GAS_PRICE,
        },
      );

      const boonActionClient: BoonActionClient = {
        signAndSendTx: async (
          walletAddress: string,
          msgs: any[],
          fee: any,
          memo: string,
        ) => {
          return await client.signAndBroadcast(walletAddress, msgs, fee, memo);
        },
      };

      return {
        client: boonActionClient,
        walletAddress: accounts[0].address,
      };
    }
  }

  private static async checkTestnet() {
    if (!window.keplr) {
      throw new NoWalletExistsError();
    }

    if (TEST_NET) {
      await window.keplr.experimentalSuggestChain(
        KeplrWallet.chainInfo(
          CHAIN_ID,
          "Kujira Testnet",
          "https://kujira-testnet-rpc.polkachu.com",
          "https://kujira-testnet-api.polkachu.com/",
          [
            {
              coinDenom: "KUJI",
              coinMinimalDenom: "ukuji",
              coinDecimals: 6,
              coinGeckoId: "kujira",
              gasPriceStep: { low: 0.0034, average: 0.0051, high: 0.00681 },
            },

            {
              coinDenom: TOKEN,
              coinMinimalDenom: TOKEN_ADDRESS,
              coinDecimals: 6,
              coinGeckoId: "kujira",
              gasPriceStep: {
                low: 0.00125,
                average: 0.0025,
                high: 0.00375,
              },
            },
          ],
        ),
      );
    }
  }

  // TODO: this is test chain data, needs to be inserted manually for testnet configuration
  public static chainInfo(
    chainId: string,
    chainName: string,
    rpc: string,
    rest: string,
    fees: FeeCurrency[] = [],
  ): ChainInfo {
    return {
      chainId,
      chainName,
      chainSymbolImageUrl: "https://assets.leapwallet.io/kujira-chain-logo.png",
      rpc,
      rest,
      bip44: {
        coinType: 118,
      },
      bech32Config: {
        bech32PrefixAccAddr: "kujira",
        bech32PrefixAccPub: "kujira" + "pub",
        bech32PrefixValAddr: "kujira" + "valoper",
        bech32PrefixValPub: "kujira" + "valoperpub",
        bech32PrefixConsAddr: "kujira" + "valcons",
        bech32PrefixConsPub: "kujira" + "valconspub",
      },
      currencies: fees,
      feeCurrencies: fees,
      stakeCurrency: {
        coinDenom: "KUJI",
        coinMinimalDenom: "ukuji",
        coinDecimals: 6,
        coinGeckoId: "kujira",
      },
    };
  }
}

export default KeplrWallet;
