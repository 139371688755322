import { useEffect } from "react";
import { TotalStakedData } from "../model";
import { ProgressState } from "../utils/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
  getTotalStakedData,
  resetTotalStakedState,
  TotalStakedState,
} from "../store/totalStakedSlice";

export interface TotalStakedHook {
  data: TotalStakedData | null;
  loading: ProgressState;
  error: string | null;
  reset: () => void;
}

export const useTotalStaked = (): TotalStakedHook => {
  const dispatch = useDispatch();

  const { data, loading, error } = useSelector<RootState, TotalStakedState>(
    (state) => state.totalStakedData,
  );

  const resetTotalStakedData = () => {
    dispatch(resetTotalStakedState());
  };

  useEffect(() => {
    dispatch(getTotalStakedData() as any);
  }, [dispatch]);

  return {
    data,
    loading,
    error,
    reset: resetTotalStakedData,
  };
};
