
const VerticalDivider = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "var(--space-0, 1px)",
        opacity: "0.1",
        background: "#FFF",
      }}
    ></div>
  );
};

export default VerticalDivider;
