import { useEffect } from "react";
import { ProgressState } from "../utils/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { getTotalVestingData, resetTotalVestingState, TotalVestingState } from "../store/totalVestingSlice";
import { VestingAmountData } from "../model";


export interface TotalVestingHook {
  data: VestingAmountData | null;
  loading: ProgressState;
  error: string | null;
  reset: () => void;
}

export const useTotalVesting = (): TotalVestingHook => {
  const dispatch = useDispatch();

  const { data, loading, error } = useSelector<RootState, TotalVestingState>(
    (state) => state.totalVestingData
  );

  const resetTotalVestingData = () => {
    dispatch(resetTotalVestingState());
  };

  useEffect(() => {
    dispatch(getTotalVestingData() as any);
  }, [dispatch]);

  return {
    data,
    loading,
    error,
    reset: resetTotalVestingData,
  };
};
