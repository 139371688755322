import React, { ChangeEvent } from "react";
import BoonIconLogo from "../../../utils/boon-icon-logo";

interface StakingInputProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const StakingInput: React.FC<StakingInputProps> = ({ value, onChange }) => {
  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (/^\d*\.?\d*$/.test(value)) {
      onChange(event);
    } else {
      event.target.value = value
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*)\./g, "$1");
      onChange(event);
    }
  };

  return (
    <div className="flex w-full rounded-[8px] border border-[rgba(255,255,255,0.10)] bg-[#07070C] p-4">
      <div className="mr-4 flex h-6 w-6 items-center">
        <BoonIconLogo />
      </div>
      <div className="flex w-full items-center justify-between">
        <input
          className="w-full appearance-none bg-transparent text-white outline-none"
          value={value}
          onChange={handleInput}
          type="text"
          placeholder="0.00"
        />
        <p className="body-lg-medium-light text-[#FFFFFF33]">$BOON</p>
      </div>
    </div>
  );
};

export default StakingInput;
