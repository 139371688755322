import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProgressState } from "../utils/types";
import {
  RPC_URL,
  DAO_REWARDS_ADDRESS,
  TOKEN_ADDRESS,
} from "../utils/constants";
import { CosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { CwRewardsQueryClient } from "../model/generated/CwRewards.client";
import { UserRewardData } from "../model";
import BigNumber from "bignumber.js";

export interface UserRewardsState {
  data: UserRewardData | null;
  loading: ProgressState;
  error: string | null;
}

const initialState: UserRewardsState = {
  data: null,
  loading: "IDLE",
  error: null,
};

export const getUserRewardsData = createAsyncThunk(
  "user/rewards",
  async (wallet_address: string, { rejectWithValue, dispatch }) => {
    try {
      const client = await CosmWasmClient.connect(RPC_URL);

      if (!client) {
        return rejectWithValue({
          message: "Wallet client not available. Please try again later",
        });
      }

      const cwRewardsQueryClient = new CwRewardsQueryClient(
        client,
        DAO_REWARDS_ADDRESS,
      );
      const pending_rewards = await cwRewardsQueryClient.pendingRewards({
        staker: wallet_address,
      });

      const user_rewards = pending_rewards.rewards.filter(
        (r) => r.denom === TOKEN_ADDRESS,
      );

      const pendingRewardsSum = user_rewards.reduce((acc, reward) => 
        acc.plus(new BigNumber(reward.amount)), new BigNumber(0));

      return { amount: pendingRewardsSum };
    } catch (err) {
      console.log(err);
      dispatch(resetUserRewardsState());
      return rejectWithValue({
        message: "Failed to fetch user rewards amount",
      });
    }
  },
);

export const userRewardsSlice = createSlice({
  name: "userRewards",
  initialState,
  reducers: {
    resetUserRewardsState: (state) => {
      state.loading = "IDLE";
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(getUserRewardsData.pending, (state: UserRewardsState) => {
        state.loading = "IN_PROGRESS";
      })
      .addCase(
        getUserRewardsData.fulfilled,
        (state: UserRewardsState, action: any) => {
          state.loading = "SUCCESS";
          state.data = action.payload;
        },
      )
      .addCase(
        getUserRewardsData.rejected,
        (state: UserRewardsState, action: any) => {
          state.data = null;
          state.loading = "FAILED";
          state.error =
            action.error.message || "Failed to fetch user rewards amount";
        },
      );
  },
});

export const { resetUserRewardsState } = userRewardsSlice.actions;

export default userRewardsSlice.reducer;
