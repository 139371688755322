import { useEffect } from "react";
import { ProgressState } from "../utils/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
  getUserUnstakedAmountData,
  resetUserUnstakedAmountState,
  UserUnstakedAmountState,
} from "../store/userUnstakedAmountSlice";
import { UserUnstakedAmountData } from "../model";
import useWallet from "./wallet/useWallet";

export interface UserUnstakedAmountHook {
  data: UserUnstakedAmountData | null;
  loading: ProgressState;
  error: string | null;
  reset: () => void;
}

export const useUserUnstakedAmount = (): UserUnstakedAmountHook => {
  const { walletAddress } = useWallet();
  
  const dispatch = useDispatch();

  const { data, loading, error } = useSelector<
    RootState,
    UserUnstakedAmountState
  >((state) => state.userUnstakedAmountData);

  const resetUserUnstakedAmountData = () => {
    dispatch(resetUserUnstakedAmountState());
  };

  useEffect(() => {
    if (!walletAddress) return;

    dispatch(getUserUnstakedAmountData(walletAddress) as any);
  }, [dispatch, walletAddress]);

  return {
    data,
    loading,
    error,
    reset: resetUserUnstakedAmountData,
  };
};
