import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProgressState } from "../utils/types";
import { RPC_URL, DAO_REWARDS_ADDRESS } from "../utils/constants";
import { CosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { UserStakedAmountData } from "../model";
import { CwRewardsQueryClient } from "../model/generated/CwRewards.client";
import BigNumber from "bignumber.js";

export interface UserStakedAmountState {
  data: UserStakedAmountData | null;
  loading: ProgressState;
  error: string | null;
}

const initialState: UserStakedAmountState = {
  data: null,
  loading: "IDLE",
  error: null,
};

export const getUserStakedAmountData = createAsyncThunk(
  "user/stakeAmount",
  async (wallet_address: string, { rejectWithValue, dispatch }) => {
    try {
      const client = await CosmWasmClient.connect(RPC_URL);

      if (!client) {
        return rejectWithValue({
          message: "Wallet client not available, please try again later",
        });
      }

      const cwRewardsQueryClient = new CwRewardsQueryClient(
        client,
        DAO_REWARDS_ADDRESS,
      );
      const amount = (
        await cwRewardsQueryClient.stakeInfo({
          staker: wallet_address,
        })
      ).amount;

      return { amount: new BigNumber(amount) };
    } catch (err) {
      console.log(err);
      dispatch(resetUserStakedAmountState());
      return rejectWithValue({ message: "Failed to fetch user staked amount" });
    }
  },
);

export const userStakedAmountSlice = createSlice({
  name: "userStakedAmount",
  initialState,
  reducers: {
    resetUserStakedAmountState: (state) => {
      state.loading = "IDLE";
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(
        getUserStakedAmountData.pending,
        (state: UserStakedAmountState) => {
          state.loading = "IN_PROGRESS";
        },
      )
      .addCase(
        getUserStakedAmountData.fulfilled,
        (state: UserStakedAmountState, action: any) => {
          state.loading = "SUCCESS";
          state.data = action.payload;
        },
      )
      .addCase(
        getUserStakedAmountData.rejected,
        (state: UserStakedAmountState, action: any) => {
          state.data = null;
          state.loading = "FAILED";
          state.error =
            action.error.message || "Failed to fetch user staked amount";
        },
      );
  },
});

export const { resetUserStakedAmountState } = userStakedAmountSlice.actions;

export default userStakedAmountSlice.reducer;
