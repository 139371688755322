import { BankExtension, QueryClient, StakingExtension } from "@cosmjs/stargate";

export type ProgressState = "IDLE" | "IN_PROGRESS" | "SUCCESS" | "FAILED";

export type HttpResponseError = {
  message?: string;
  messages?: string[];
};

export type BunQueryClient = QueryClient & StakingExtension & BankExtension;

export enum ChartItem {
  Vesting = "Vesting",
  Staked = "Staked",
  Liquid = "Liquid",
  Claimable = "Claimable",
  Unstaking = "Unstaking",
}
