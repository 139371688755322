import Modal from "react-modal";

const BoonModal = ({
  children,
  isModalOpen,
  setIsModalOpen,
}: {
  children: JSX.Element;
  isModalOpen: boolean;
  setIsModalOpen: (val: boolean) => void;
}) => {
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "500px",
          maxHeight: "300px",
          width: "90%",
          height: "90%",
          borderRadius: "8px",
          padding: "0px",
          border: "0px",
        },
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      {children}
    </Modal>
  );
};

export default BoonModal;
