import useWallet, { Wallet } from "../../hooks/wallet/useWallet";
import { circle } from "../../utils/icons";
import Button from "../Button";
import SonarLogo from "../../assets/sonar.png";
import KeplrLogo from "../../assets/keplr.png";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { TailSpin } from "react-loader-spinner";

const WalletNotConnected = ({ onConnect }: { onConnect: () => void }) => {
  const { connect } = useWallet();
  const { loading } = useSelector((state: RootState) => state.walletData);

  return (
    <div className="flex h-full w-full flex-col rounded-[8px] border-[1px] border-surface-800 bg-surface-900 p-space-6">
      <div className="mb-space-4 flex flex-col">
        <p className="mb-space-1 font-body-xl-medium text-body-xl-medium leading-body-xl-medium tracking-body-xl-medium text-surface-100">
          Browser Wallets
        </p>
        <p className="font-body-lg-regular text-body-lg-regular leading-body-lg-regular tracking-body-lg-regular text-surface-500">
          Create or connect an existing account
        </p>
      </div>
      {loading === "IN_PROGRESS" ? (
        <TailSpin height={40} width={40} color="#9282FF" />
      ) : (
        <div className="flex w-full flex-row">
          <button
            className="flex"
            onClick={() => {
              connect(Wallet.Keplr, onConnect);
            }}
          >
            <img
              src={KeplrLogo}
              alt="Keplr"
              className="h-12 w-12 transition-transform hover:scale-110"
            />
          </button>
          {!isMobile && (
            <button
              className="ml-space-6"
              onClick={() => {
                connect(Wallet.Sonar, onConnect);
              }}
            >
              <img
                src={SonarLogo}
                alt="Sonar"
                className="h-12 w-12 transition-transform hover:scale-110"
              />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

const WalletConnected = () => {
  const { disconnect, walletAddress } = useWallet();

  const displayWalletAddressShort =
    walletAddress && `${walletAddress.slice(0, 4)}`;

  const displayWalletAddressLong =
    walletAddress &&
    `${walletAddress.slice(0, 7)}...${walletAddress.slice(walletAddress.length - 7)}`;

  return (
    <div className="flex h-full w-full flex-col rounded-[8px] border-[1px] border-surface-800 bg-surface-900 p-space-6">
      <div className="mb-space-5 flex items-center justify-center">
        {circle()}
        <p className="ml-space-3 mr-space-3 text-surface-100 sm:hidden">
          {displayWalletAddressShort}
        </p>
        <p className="ml-space-3 mr-space-3 hidden text-surface-100 sm:block">
          {displayWalletAddressLong}
        </p>
      </div>
      <div>
        <Button text="Disconnect Wallet" onClick={() => disconnect()} />
      </div>
    </div>
  );
};

const WalletsPopover = ({ onConnect }: { onConnect: () => void }) => {
  const { isWalletConnected } = useWallet();

  return (
    <>
      {isWalletConnected ? (
        <WalletConnected />
      ) : (
        <WalletNotConnected onConnect={onConnect} />
      )}
    </>
  );
};

export default WalletsPopover;
