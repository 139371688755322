import useWallet from "../../hooks/wallet/useWallet";
import StakingInfoHeader from "./StakingInfoHeader";
import StakingDetail from "./StakingDetail";
import Button from "../Button";
import VerticalDivider from "../VerticalDivider";
import { useUserUnstakedAmount } from "../../hooks/useUserTotalUnstakedAmount";
import { useEffect } from "react";
import { divideAndFormatBoon, ToastType } from "../../utils";
import { useUserRewards } from "../../hooks/useUserRewards";
import { useDispatch, useSelector } from "react-redux";
import { getUserBalanceData } from "../../store/userBalanceSlice";
import { getUserStakedAmountData } from "../../store/userStakedAmountSlice";
import { getUserUnstakedAmountData } from "../../store/userUnstakedAmountSlice";
import { getUserRewardsData } from "../../store/userRewardsSlice";
import { RootState } from "../../store";
import { setMessage } from "../../store/notificationsSlice";
import { setClaimProgress } from "../../store/tokenManagementSlice";
import { TailSpin } from "react-loader-spinner";
import BigNumber from "bignumber.js";
import { useUserVesting } from "../../hooks/useUserVesting";
import { getUserVestingData } from "../../store/userVestingSlice";
import { VESTING_WHITELIST } from "../../utils/constants";

const Claim = () => {
  const dispatch = useDispatch();
  const { isWalletConnected, walletAddress, claim } = useWallet();

  const { data: userUnstakedData } = useUserUnstakedAmount();

  const { data: rewardsData } = useUserRewards();

  const { data: userVestingData } = useUserVesting();

  const { claimProgress, message: claimMessage } = useSelector(
    (state: RootState) => state.tokenManagementData,
  );

  useEffect(() => {
    if (walletAddress) {
      if (claimProgress === "SUCCESS") {
        dispatch(getUserBalanceData(walletAddress) as any);
        dispatch(getUserStakedAmountData(walletAddress) as any);
        dispatch(getUserUnstakedAmountData(walletAddress) as any);
        dispatch(getUserRewardsData(walletAddress) as any);
        dispatch(getUserVestingData(walletAddress) as any);
        dispatch(
          setMessage({
            content: claimMessage,
            type: ToastType.Success,
          }),
        );
        dispatch(setClaimProgress({ claimProgress: "IDLE", message: null }));
      } else if (claimProgress === "FAILED") {
        dispatch(
          setMessage({
            content: claimMessage,
            type: ToastType.Error,
          }),
        );
        dispatch(setClaimProgress({ claimProgress: "IDLE", message: null }));
      }
    }
  }, [claimMessage, claimProgress, dispatch, walletAddress]);

  const claimAction = (
    reward: BigNumber,
    claimableUstakedAmount: BigNumber,
  ) => {
    claim(reward, claimableUstakedAmount);
  };

  const nonClaimableUnstakedNum = userUnstakedData?.not_claimable || new BigNumber(0);
  const nonClaimableUnstaked = divideAndFormatBoon(nonClaimableUnstakedNum, 10);

  const claimableUnstakedNum = userUnstakedData?.claimable || new BigNumber(0);
  const claimableUnstaked = divideAndFormatBoon(claimableUnstakedNum, 10);

  const rewardAmountNum = rewardsData?.amount || new BigNumber(0);
  const rewardAmount = divideAndFormatBoon(rewardAmountNum, 10);

  const userVestingClaimableNum =
    userVestingData?.claimableAmount || new BigNumber(0);
  const userVestingClaimable = divideAndFormatBoon(userVestingClaimableNum, 10);

  let totalClaimableAmountNum = rewardAmountNum.plus(claimableUnstakedNum);
  if (walletAddress && VESTING_WHITELIST.split(',').includes(walletAddress)) {
    totalClaimableAmountNum = totalClaimableAmountNum.plus(userVestingClaimableNum);
  }
  const totalClaimableAmount = divideAndFormatBoon(totalClaimableAmountNum, 10);

  return (
    <div className="rounded-[8px] border border-surface-800 bg-surface-900 pb-[42px] pl-6 pr-6 pt-[42px] lg:pl-[80px] lg:pr-[80px]">
      <div className="grid grid-cols-1 gap-y-[42px]">
        <StakingInfoHeader
          title="Unbonded Tokens"
          subtitle="Tokens that have been unstaked and currently unbonding are displayed below. You can only claim all unbonded tokens"
        />
        <div className="flex flex-col items-center gap-y-6">
          <div className="flex w-full flex-col gap-y-4">
            <StakingDetail text="Unbonding" value={nonClaimableUnstaked} />
          </div>
          <VerticalDivider />
          <div className="flex w-full flex-col gap-y-4">
            <StakingDetail text="Claimable unstaked tokens" value={claimableUnstaked} />
            <StakingDetail
              text="Claimable staking rewards"
              value={rewardAmount}
            />
            {walletAddress && VESTING_WHITELIST.split(',').includes(walletAddress) && (
              <StakingDetail
                text="Claimable vested tokens"
                value={userVestingClaimable}
              />
            )}
          </div>

          <VerticalDivider />
          <StakingDetail text="Total claimable $BOON" value={totalClaimableAmount} />

          {isWalletConnected && (
            <div className="flex w-full justify-center gap-x-6">
              {claimProgress === "IN_PROGRESS" ? (
                <TailSpin height={40} width={40} color="#9282FF" />
              ) : (
                <Button
                  text="Claim"
                  onClick={() =>
                    claimAction(rewardAmountNum, claimableUnstakedNum)
                  }
                  disabled={totalClaimableAmountNum.lessThanOrEqualTo(0)}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Claim;
