interface StakingInfoHeaderProps {
  title: string;
  subtitle: string;
}

const StakingInfoHeader = ({ title, subtitle }: StakingInfoHeaderProps) => {
  return (
    <div className="flex flex-col gap-y-4">
      <p className="title-xl-medium-light text-center">{title}</p>
      <p className="body-md-regular-light text-center text-surface-200 opacity-50">
        {subtitle}
      </p>
    </div>
  );
};

export default StakingInfoHeader;
