import { AccountData, Algo, GeneratedType, Registry } from "@cosmjs/proto-signing";
import { defaultRegistryTypes } from "@cosmjs/stargate";
import { Buffer } from "buffer";
import UniversalProvider from "@walletconnect/universal-provider";
import { WalletConnectModal } from "@walletconnect/modal";
import Client from "@walletconnect/sign-client";
import { NoAccountFoundError } from "./errors";
import { CHAIN_ID, RPC_URL } from "../../utils/constants";
import { fromByteArray, toByteArray } from "base64-js";
import { connectComet } from "@cosmjs/tendermint-rpc/build/tendermintclient";
import { BoonActionClient } from "../../model";
import { MsgExecuteContract } from "cosmjs-types/cosmwasm/wasm/v1/tx";


/* Note: Sonar doesn't have testnet support, keep it in mind */

export const GENERAL_SONAR_CONNECTION_ERROR_MESSAGE =
  "Please try again connecting Sonar wallet later";

const chainId = `cosmos:${CHAIN_ID}`;

const walletConnectProjectId = "09665e2fbe30ef5c3b8033f2dcbff36b";

export const getAccounts = async (
  client: Client,
  session: any,
): Promise<AccountData[]> => {
  const res = await client.request<string>({
    topic: session.topic,
    chainId: chainId,
    request: {
      method: "cosmos_getAccounts",
      params: {},
    },
  });

  const data = JSON.parse(res);
  const accountData: AccountData = {
    ...data,
    pubkey: Buffer.from(data.pubkey, "base64"),
  };
  return [accountData];
};

// https://docs.walletconnect.com/2.0/javascript/sign/dapp-usage
class SonarWallet {
  public account: AccountData;

  private constructor(
    private connector: Client,
    public session: any,
  ) {
    const [account] = session.namespaces["cosmos"].accounts.map(
      (address: any) => ({
        address: address.split(":")[2],
        pubkey: new Uint8Array(),
        algo: "secp256k1" as Algo,
      }),
    );

    this.account = account;
  }

  static connect = async (
    _network: string = CHAIN_ID,
  ): Promise<{
    walletAddress: string;
    client: BoonActionClient;
  }> => {
    // Initialize WalletConnect provider
    const provider = await UniversalProvider.init({
      projectId: walletConnectProjectId,
    });

    const requiredNamespaces = {
      cosmos: {
        chains: [chainId],
        methods: [],
        events: [],
      },
    };

    const { uri, approval } = await provider.client.connect({
      requiredNamespaces,
      optionalNamespaces: {
        [chainId]: {
          methods: [
            "cosmos_signDirect",
            "cosmos_signAmino",
            "cosmos_getAccounts",
          ],
          events: [],
        },
      },
    });

    const walletConnectModal = new WalletConnectModal({
      projectId: walletConnectProjectId,
      enableExplorer: false,
    });

    if (uri) {
      walletConnectModal.openModal({ uri });
    }
    const walletConnectSession = await approval();
    try {
      const accounts = await getAccounts(provider.client, walletConnectSession);
      walletConnectModal.closeModal();

      const actionClient: BoonActionClient = {
        signAndSendTx: async (
          walletAddress: string,
          msgs: any[],
          fee: any,
          memo: string,
        ) => {

          const customRegistryTypes: ReadonlyArray<[string, GeneratedType]> = [
            ["/cosmwasm.wasm.v1.MsgExecuteContract", MsgExecuteContract]
          ]

          const ProtoRegistry = new Registry([...defaultRegistryTypes, ...customRegistryTypes]);

          const params = {
            feeDenom: "ukuji",
            memo: memo,
            msgs: msgs.map((msg) => {
              return {
                typeUrl: msg.typeUrl,
                value: fromByteArray(ProtoRegistry.encode(msg)),
              };
            }),
          };

          const txSigned = toByteArray(
            await provider.client.request<string>({
              topic: walletConnectSession.topic,
              chainId: chainId,
              request: { method: "cosmos_signDirect", params },
            }),
          );

          const cmtClient = await connectComet(RPC_URL);
          return await cmtClient.broadcastTxSync({ tx: txSigned });
        },
      };

      return {
        walletAddress: accounts[0].address,
        client: actionClient,
      };
    } catch (err) {
      throw new NoAccountFoundError();
    }
  };

  public onChange = (fn: (k: SonarWallet | null) => void) => {
    this.connector.on("session_delete", () => {
      fn(null);
    });
  };

  public disconnect = () => {
    this.connector.disconnect({
      topic: this.session.topic,
      reason: { code: 1, message: "USER_CLOSED" },
    });
  };
}

export default SonarWallet;
