import React, { createContext, useContext, useState, ReactNode } from "react";
import { BoonActionClient } from "../../model";

type WalletContextType = {
  client: BoonActionClient | null; // Replace 'any' with the actual type of your client
  setClient: (client: BoonActionClient | null) => void;
};

const WalletContext = createContext<WalletContextType | undefined>(undefined);

export const WalletProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [client, setClient] = useState<BoonActionClient | null>(null);

  return (
    <WalletContext.Provider value={{ client, setClient }}>
      {children}
    </WalletContext.Provider>
  );
};

export const useWalletContext = () => {
  const context = useContext(WalletContext);
  if (context === undefined) {
    throw new Error("useWalletContext must be used within a WalletProvider");
  }
  return context;
};
