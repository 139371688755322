import BigNumber from "bignumber.js";
import { divideAndFormatBoon } from "../../../utils";

interface DashboardChartDataItemProps {
  text: string;
  value: BigNumber;
  color: string;
  fractionDigits?: number;
}

const DashboardChartDataItem = ({
  text,
  value,
  color,
  fractionDigits,
}: DashboardChartDataItemProps) => {
  return (
    <div className="flex items-center justify-between self-stretch">
      <div className="flex items-center gap-2.5 pb-1 pt-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2"
          height="24"
          viewBox="0 0 2 24"
          fill="none"
        >
          <path
            d="M1 1L0.999999 23"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
        <p className="body-lg-regular-light">{text}</p>
      </div>
      <p className="body-lg-bold-light opacity-70">
        {/* {value.toString()} */}
        {divideAndFormatBoon(value, fractionDigits)}
      </p>
    </div>
  );
};

export default DashboardChartDataItem;
