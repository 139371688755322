import { createSlice } from "@reduxjs/toolkit";

export interface TokenManagementState {
  stakeProgress: "IDLE" | "IN_PROGRESS" | "SUCCESS" | "FAILED";
  claimProgress: "IDLE" | "IN_PROGRESS" | "SUCCESS" | "FAILED";
  message: string | null;
}

const initialState: TokenManagementState = {
  stakeProgress: "IDLE",
  claimProgress: "IDLE",
  message: null,
};

export const tokenManagementSlice = createSlice({
  name: "tokenManagementSlice",
  initialState,
  reducers: {
    setStakeProgress: (state, action) => {
      state.stakeProgress = action.payload.stakeProgress;
      state.message = action.payload.message;
    },
    setClaimProgress: (state, action) => {
      state.claimProgress = action.payload.claimProgress;
      state.message = action.payload.message;
    },
  },
});

export const { setStakeProgress, setClaimProgress } =
  tokenManagementSlice.actions;

export default tokenManagementSlice.reducer;
