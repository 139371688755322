import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes, { type IRoute } from "./routes";
import { Provider, useDispatch, useSelector } from "react-redux";
import { RootState, store } from "./store";
import toast, { Toaster } from "react-hot-toast";
import type { Window as KeplrWindow } from "@keplr-wallet/types";
import Navigation from "./components/Navigation";
import TabSwitcher from "./components/TabSwitcher";
import { Drawer } from "vaul";
import ErrorBoundary from "./components/ErrorBoundary";
import { useEffect } from "react";
import { ToastType } from "./utils";
import { resetMessage } from "./store/notificationsSlice";
import { WalletProvider } from "./store/context/WalletContext";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Window extends KeplrWindow {}
}

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <Drawer.Root>
          <div className="min-h-100vh [background:radial-gradient(34.87%_65.42%_at_50.73%_42.04%,rgba(32,24,122,0.32)_0%,rgba(12,2,34,0)_100%),#0F0F11]">
            <WalletProvider>
              <ErrorBoundary>
                <Toaster />
                <Navigation />
                <TabSwitcher />
                <Pages />
              </ErrorBoundary>
            </WalletProvider>
          </div>
        </Drawer.Root>
      </Provider>
    </BrowserRouter>
  );
}

const Pages = () => {
  const dispatch = useDispatch();
  const { message } = useSelector((state: RootState) => state.notificationData);

  useEffect(() => {
    if (!message) return;
    if (message.type === ToastType.Success) {
      toast.success(message.content);
    } else if (message.type === ToastType.Error) {
      toast.error(message.content);
    } else {
      toast(message.content);
    }
    dispatch(resetMessage());
  }, [message, dispatch]);

  return (
    <Routes>
      {routes.map((route: IRoute) => (
        <Route key={route.id} path={route.path} element={<route.element />} />
      ))}
    </Routes>
  );
};

export default App;
