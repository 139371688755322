import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProgressState } from "../utils/types";
import { RPC_URL, DAO_ADDRESS } from "../utils/constants";
import { CosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { UserUnstakedAmountData } from "../model";
import BigNumber from "bignumber.js";

export interface UserUnstakedAmountState {
  data: UserUnstakedAmountData | null;
  loading: ProgressState;
  error: string | null;
}

const initialState: UserUnstakedAmountState = {
  data: null,
  loading: "IDLE",
  error: null,
};

export const getUserUnstakedAmountData = createAsyncThunk(
  "user/unstakedAmount",
  async (wallet_address: string, { rejectWithValue, dispatch }) => {
    try {
      const client = await CosmWasmClient.connect(RPC_URL);

      if (!client) {
        return rejectWithValue({
          message: "Wallet client not available, please try again later",
        });
      }

      const voting_module = await client.queryContractSmart(DAO_ADDRESS, {
        voting_module: {},
      });
      const unstakeRequestsQueryResult: any[] = (
        await client.queryContractSmart(voting_module, {
          claims: { address: wallet_address },
        })
      ).claims;
      const unstakeRequests = unstakeRequestsQueryResult.map((claim) => {
        return {
          amount: new BigNumber(claim.amount),
          timestamp: BigInt(claim.release_at.at_time),
        };
      });

      const height = await client.getHeight();
      const currentBlock = await client.getBlock(height);

      const currentTimestamp =
        BigInt(new Date(currentBlock.header.time).getTime()) * BigInt(1e6);

      const notClaimableAmounts = unstakeRequests
        .filter((claim) => claim.timestamp > currentTimestamp)
        .map((claim) => claim.amount);

      const claimableAmounts = unstakeRequests
        .filter((claim) => claim.timestamp <= currentTimestamp)
        .map((claim) => claim.amount);

      const claimable_amount: BigNumber = claimableAmounts.reduce(
        (accumulator, currentValue) => {
          return accumulator.plus(currentValue);
        },
        new BigNumber(0)
      );
      const not_claimable_amount: BigNumber = notClaimableAmounts.reduce(
        (accumulator, currentValue) => {
          return accumulator.plus(currentValue);
        },
        new BigNumber(0)
      );

      return {
        not_claimable: not_claimable_amount,
        claimable: claimable_amount,
      };
    } catch (err) {
      console.log(err);
      dispatch(resetUserUnstakedAmountState());
      return rejectWithValue({
        message: "Failed to fetch user unstaked amount",
      });
    }
  },
);

export const userUnstakedAmountSlice = createSlice({
  name: "userUnstakedAmount",
  initialState,
  reducers: {
    resetUserUnstakedAmountState: (state) => {
      state.loading = "IDLE";
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(
        getUserUnstakedAmountData.pending,
        (state: UserUnstakedAmountState) => {
          state.loading = "IN_PROGRESS";
        },
      )
      .addCase(
        getUserUnstakedAmountData.fulfilled,
        (state: UserUnstakedAmountState, action: any) => {
          state.loading = "SUCCESS";
          state.data = action.payload;
        },
      )
      .addCase(
        getUserUnstakedAmountData.rejected,
        (state: UserUnstakedAmountState, action: any) => {
          state.data = null;
          state.loading = "FAILED";
          state.error =
            action.error.message || "Failed to fetch user unstaked amount";
        },
      );
  },
});

export const { resetUserUnstakedAmountState } = userUnstakedAmountSlice.actions;

export default userUnstakedAmountSlice.reducer;
