import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProgressState } from "../utils/types";
import { TotalStakedData } from "../model";
import { DAO_VOTING_ADDRESS, TOKEN_ADDRESS } from "../utils/constants";
import { initializeQueryClient } from "../utils/queryClient";
import BigNumber from "bignumber.js";

export interface TotalStakedState {
  data: TotalStakedData | null;
  loading: ProgressState;
  error: string | null;
}

const initialState: TotalStakedState = {
  data: null,
  loading: "IDLE",
  error: null,
};

export const getTotalStakedData = createAsyncThunk(
  "total/staked",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const queryClient = await initializeQueryClient();
      const stakedData = await queryClient?.bank.balance(DAO_VOTING_ADDRESS, TOKEN_ADDRESS);
      const staked = stakedData ? new BigNumber(stakedData.amount) : new BigNumber(0);
      return { amount: staked };
    } catch (err) {
      console.log(err);
      dispatch(resetTotalStakedState());
      return rejectWithValue({ message: "Failed to fetch total staked" });
    }
  },
);

export const totalStakedSlice = createSlice({
  name: "totalStaked",
  initialState,
  reducers: {
    resetTotalStakedState: (state) => {
      state.loading = "IDLE";
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(getTotalStakedData.pending, (state: TotalStakedState) => {
        state.loading = "IN_PROGRESS";
      })
      .addCase(
        getTotalStakedData.fulfilled,
        (state: TotalStakedState, action: any) => {
          state.loading = "SUCCESS";
          state.data = action.payload;
        },
      )
      .addCase(
        getTotalStakedData.rejected,
        (state: TotalStakedState, action: any) => {
          state.data = null;
          state.loading = "FAILED";
          state.error = action.error.message || "Failed fetch total staked";
        },
      );
  },
});

export const { resetTotalStakedState } = totalStakedSlice.actions;

export default totalStakedSlice.reducer;
