import { useEffect } from "react";
import { ProgressState } from "../utils/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
  getUserStakedAmountData,
  resetUserStakedAmountState,
  UserStakedAmountState,
} from "../store/userStakedAmountSlice";
import { UserStakedAmountData } from "../model";
import useWallet from "./wallet/useWallet";

export interface UserStakedAmountHook {
  data: UserStakedAmountData | null;
  loading: ProgressState;
  error: string | null;
  reset: () => void;
}

export const useUserStakedAmount = (): UserStakedAmountHook => {
  const { walletAddress } = useWallet();

  const dispatch = useDispatch();

  const { data, loading, error } = useSelector<
    RootState,
    UserStakedAmountState
  >((state) => state.userStakedAmountData);

  const resetUserStakedAmountData = () => {
    dispatch(resetUserStakedAmountState());
  };

  useEffect(() => {
    if (!walletAddress) return;

    dispatch(getUserStakedAmountData(walletAddress) as any);
  }, [dispatch, walletAddress]);

  return {
    data,
    loading,
    error,
    reset: resetUserStakedAmountData,
  };
};
