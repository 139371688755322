import { useLocation, useNavigate } from "react-router-dom";
import MenuItem from "./MenuItem";

const TabSwitcher = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isDashboardChosen = location.pathname === "/";
  const isStakingChosen = location.pathname === "/token_management";

  return (
    <div className="mt-space-11 grid items-center">
      <div className="grid justify-center">
        <div className="rounded-lg border-[1px] border-surface-800 bg-surface-900 px-space-3 py-space-2">
          <MenuItem
            onClick={() => navigate("/")}
            text={"Dashboard"}
            active={isDashboardChosen}
          />

          <MenuItem
            onClick={() => {
              navigate("/token_management");
            }}
            text={"Manage Tokens"}
            active={isStakingChosen}
            addonClassNames={{ "ml-space-4": true }}
          />
        </div>
      </div>
    </div>
  );
};

export default TabSwitcher;
