import StakeUnstake from "../components/TokenManagement/StakeUnstake";
import Claim from "../components/TokenManagement/Claim";

const TokenManagement = () => {
  return (
    <div className="mt-11 grid grid-cols-1 gap-y-8 px-5 lg:ml-[190px] lg:mr-[190px] lg:grid-cols-2 lg:gap-x-8">
      <StakeUnstake />
      <Claim />
    </div>
  );
};

export default TokenManagement;
