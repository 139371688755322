import { Tooltip } from "react-tooltip";

interface DashboardTopDataItemComponentProps {
  formattedText: string;
  rawText: string;
  description: string;
}

const DashboardHeaderItem = ({
  formattedText,
  rawText,
  description,
}: DashboardTopDataItemComponentProps) => {
  const textToShow =
    formattedText.length > 10
      ? formattedText.slice(0, 7) + "..."
      : formattedText;

  return (
    <div className="flex h-full flex-row items-center justify-start md:flex-col">
      <div className="mb-space-1 text-center">
        <p
          className="title-lg-bold-light cursor-default"
          data-tooltip-id="dashboard-header-tooltip"
          data-tooltip-content={rawText}
        >
          {textToShow}
        </p>
        <Tooltip id="dashboard-header-tooltip" />
      </div>
      <div className="ml-space-2 text-center md:ml-0">
        <p className="body-lg-regular-dark">{description}</p>
      </div>
    </div>
  );
};

export default DashboardHeaderItem;
