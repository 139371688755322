import { useEffect, useState } from "react";
import DashboardChart from "./DashboardChart";
import { CHART_COLOR_MAPPINGS } from "../../../utils/colors";
import { ChartItem } from "../../../utils/types";
import { useTotalSupply } from "../../../hooks/useTotalSupply";
import { useTotalStaked } from "../../../hooks/useTotalStaked";
import BigNumber from "bignumber.js";
import { ChartDataItem } from "../../../model";
import { useTotalVesting } from "../../../hooks/useTotalVesting";
import { useTotalReward } from "../../../hooks/useTotalReward";

const INITIAL_CHART_DATA: ChartDataItem[] = [
  {
    name: ChartItem.Liquid,
    value: new BigNumber(0),
    color: CHART_COLOR_MAPPINGS.Liquid,
  },
  {
    name: ChartItem.Staked,
    value: new BigNumber(0),
    color: CHART_COLOR_MAPPINGS.Staked,
  },
  {
    name: ChartItem.Vesting,
    value: new BigNumber(0),
    color: CHART_COLOR_MAPPINGS.Vesting,
  },
];

const GlobalDashboardChart = () => {
  const [data, setData] = useState<{
    totalValue: BigNumber;
    chartDataItems: ChartDataItem[];
  }>({
    totalValue: new BigNumber(0),
    chartDataItems: INITIAL_CHART_DATA,
  });

  const { data: supplyData } = useTotalSupply();

  const { data: stakedData, loading: stakedLoading } = useTotalStaked();

  const {
    data: rewardData,
    loading: rewardLoading,
  } = useTotalReward();

  const { data: vestingData, loading: vestingLoading } = useTotalVesting();

  useEffect(() => {
    if (
      stakedLoading === "SUCCESS" &&
      rewardLoading === "SUCCESS" &&
      vestingLoading === "SUCCESS" &&
      stakedData &&
      stakedData.amount &&
      supplyData &&
      supplyData.amount &&
      vestingData &&
      vestingData.claimableAmount &&
      vestingData.nonClaimableAmount &&
      rewardData &&
      rewardData.amount
    ) {
      const totalSupply = supplyData.amount ? new BigNumber(supplyData.amount) : new BigNumber(0);
      const totalStaked = stakedData.amount ? new BigNumber(stakedData.amount) : new BigNumber(0);
      const totalVesting = vestingData.nonClaimableAmount ? new BigNumber(vestingData.nonClaimableAmount) : new BigNumber(0);
      const totalRewards = rewardData.amount ? new BigNumber(rewardData.amount) : new BigNumber(0);
      const totalLiquid = totalSupply.minus(totalStaked).minus(totalVesting).minus(totalRewards);

      setData((prev) => ({
        totalValue: supplyData.amount || new BigNumber(0),
        chartDataItems: [
          { ...prev.chartDataItems[0], value: totalLiquid },
          { ...prev.chartDataItems[1], value: totalStaked },
          { ...prev.chartDataItems[2], value: totalVesting },
        ],
      }));
    }
  }, [stakedLoading, stakedData, supplyData, vestingLoading, vestingData, rewardLoading, rewardData]);

  return (
    <DashboardChart
      title="Global $BOON Statistics"
      chartDataItems={data.chartDataItems}
      totalTitle="Total Supply"
      totalValue={data.totalValue}
    />
  );
};

export default GlobalDashboardChart;
