import { Drawer } from "vaul";

const BottomSheet = ({
  children,
  title,
  subtitle,
}: {
  isOpen?: boolean;
  setIsOpen?: (val: boolean) => void;
  children?: JSX.Element;
  title?: string;
  subtitle?: string;
}) => {
  return (
    <Drawer.Portal>
      <Drawer.Overlay className="z-2 fixed inset-0 bg-black/40" />
      <Drawer.Content className="fixed bottom-0 left-0 right-0 mt-24 flex h-[60%] flex-col rounded-[8px] border-[1px] border-surface-800 bg-surface-900 px-space-4 py-space-6">
        <Drawer.Title className="text-body-xl-medium text-white">
          {title}
        </Drawer.Title>
        <p className="text-body-md-regular text-surface-600">{subtitle}</p>
        <div className="mx-auto max-w-md">{children}</div>
        {/* </div> */}
      </Drawer.Content>
      <Drawer.Description></Drawer.Description>
    </Drawer.Portal>
  );
};

export default BottomSheet;
