import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProgressState } from "../utils/types";
import { CosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import BigNumber from "bignumber.js";
import { CwVestingFactoryQueryClient } from "../model/generated/CwVestingFactory.client";
import { CwVestingQueryClient } from "../model/generated/CwVesting.client";
import { MDAO_VESTING_ADDRESS, RPC_URL, TOKEN_ADDRESS } from "../utils/constants";
import { initializeQueryClient } from "../utils/queryClient";
import { VestingAmountData } from "../model";

export interface UserVestingState {
  data: VestingAmountData | null;
  loading: ProgressState;
  error: string | null;
}

const initialState: UserVestingState = {
  data: null,
  loading: "IDLE",
  error: null,
};

export const getUserVestingData = createAsyncThunk(
  "user/vesting",
  async (recipient: string, { rejectWithValue, dispatch }) => {
    try {
      const client = await CosmWasmClient.connect(RPC_URL);
      const queryClient = await initializeQueryClient();
      const vestingFactoryQueryClient = new CwVestingFactoryQueryClient(client, MDAO_VESTING_ADDRESS);
      const schedules = await vestingFactoryQueryClient.listVestingContractsByRecipient({ recipient });
      
      let nonClaimable = new BigNumber(0);
      let claimable = new BigNumber(0);

      for (const s of schedules) {
        const balance = await queryClient.bank.balance(s.contract, TOKEN_ADDRESS);
        if (balance.amount === "0") {
          continue;
        }
        const vestingQueryClient = new CwVestingQueryClient(client, s.contract);
        const claimableAmount = await vestingQueryClient.distributable({});
        nonClaimable = nonClaimable.plus(new BigNumber(balance.amount).minus(new BigNumber(claimableAmount)));
        claimable = claimable.plus(new BigNumber(claimableAmount));
      }

      return {
        nonClaimableAmount: nonClaimable,
        claimableAmount: claimable,
      };
    } catch (err) {
      console.log(err);
      dispatch(resetUserVestingState());
      return rejectWithValue({
        message: "Failed to fetch user vesting amounts",
      });
    }
  },
);

export const userVestingSlice = createSlice({
  name: "userVesting",
  initialState,
  reducers: {
    resetUserVestingState: (state) => {
      state.loading = "IDLE";
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserVestingData.pending, (state) => {
        state.loading = "IN_PROGRESS";
      })
      .addCase(getUserVestingData.fulfilled, (state, action) => {
        state.loading = "SUCCESS";
        state.data = action.payload;
      })
      .addCase(getUserVestingData.rejected, (state, action) => {
        state.data = null;
        state.loading = "FAILED";
        state.error = action.payload as string;
      });
  },
});

export const { resetUserVestingState } = userVestingSlice.actions;

export default userVestingSlice.reducer;
