import { configureStore } from "@reduxjs/toolkit";
import tokenPriceSliceReducer from "./tokenPriceSlice";
import totalSupplySliceReducer from "./totalSupplySlice";
import totalStakersSliceReducer from "./totalStakersSlice";
import userStakedAmountSliceReducer from "./userStakedAmountSlice";
import userRewardsSliceReducer from "./userRewardsSlice";
import userUnstakedAmountSliceReducer from "./userUnstakedAmountSlice";
import walletSliceReducer from "./walletSlice";
import totalStakedReducer from "./totalStakedSlice";
import totalRewardReducer from "./totalRewardSlice";
import userBalanceReducer from "./userBalanceSlice";
import notificationReducer from "./notificationsSlice";
import tokenManagementReducer from "./tokenManagementSlice";
import totalVestingReducer from "./totalVestingSlice";
import userVestingReducer from "./userVestingSlice";

export const store = configureStore({
  reducer: {
    tokenPriceData: tokenPriceSliceReducer,
    totalSupplyData: totalSupplySliceReducer,
    totalStakersData: totalStakersSliceReducer,
    userStakedAmountData: userStakedAmountSliceReducer,
    userRewardsData: userRewardsSliceReducer,
    userUnstakedAmountData: userUnstakedAmountSliceReducer,
    walletData: walletSliceReducer,
    totalStakedData: totalStakedReducer,
    totalRewardData: totalRewardReducer,
    userBalanceData: userBalanceReducer,
    notificationData: notificationReducer,
    tokenManagementData: tokenManagementReducer,
    totalVestingData: totalVestingReducer,
    userVestingData: userVestingReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
