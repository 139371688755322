import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HttpResponseError, ProgressState } from "../utils/types";
import { TokenPriceData } from "../model";
import { REST_URL, TOKEN } from "../utils/constants";
import axios, { AxiosError } from "axios";
import { getSingleErrorMessage } from "../utils";
import BigNumber from "bignumber.js";

export interface TokenPriceState {
  data: TokenPriceData | null;
  loading: ProgressState;
  error: string | null;
}

const initialState: TokenPriceState = {
  data: null,
  loading: "IDLE",
  error: null,
};

export const getTokenPriceData = createAsyncThunk(
  "token/price",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `${REST_URL}oracle/denoms/${TOKEN}/exchange_rate`,
      );
      const price = new BigNumber(new BigNumber(response.data.exchange_rate).toFixed(6));
      return { price };
    } catch (err) {
      console.log(err);
      const error = err as AxiosError<HttpResponseError>;
      const message = getSingleErrorMessage(
        error.response?.data,
        "Failed to get Token Price",
      );
      dispatch(resetTokenPriceState());
      return rejectWithValue({ message });
    }
  },
);

export const tokenPriceSlice = createSlice({
  name: "tokenPrice",
  initialState,
  reducers: {
    resetTokenPriceState: (state) => {
      state.loading = "IDLE";
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(getTokenPriceData.pending, (state: TokenPriceState) => {
        state.loading = "IN_PROGRESS";
      })
      .addCase(
        getTokenPriceData.fulfilled,
        (state: TokenPriceState, action: any) => {
          state.loading = "SUCCESS";
          state.data = action.payload;
        },
      )
      .addCase(
        getTokenPriceData.rejected,
        (state: TokenPriceState, action: any) => {
          state.data = null;
          state.loading = "FAILED";
          state.error = action.error.message || "Failed fetch token price";
        },
      );
  },
});

export const { resetTokenPriceState } = tokenPriceSlice.actions;

export default tokenPriceSlice.reducer;
