import { useEffect } from "react";
import { ProgressState } from "../utils/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { getUserVestingData, resetUserVestingState, UserVestingState } from "../store/userVestingSlice";
import { VestingAmountData } from "../model";
import useWallet from "./wallet/useWallet";

export interface UserVestingHook {
  data: VestingAmountData | null;
  loading: ProgressState;
  error: string | null;
  reset: () => void;
}

export const useUserVesting = (): UserVestingHook => {
  const { walletAddress } = useWallet();
  const dispatch = useDispatch();

  const { data, loading, error } = useSelector<RootState, UserVestingState>(
    (state) => state.userVestingData
  );

  const resetUserVestingData = () => {
    dispatch(resetUserVestingState());
  };

  useEffect(() => {
    if (!walletAddress) return;

    dispatch(getUserVestingData(walletAddress) as any);
  }, [dispatch, walletAddress]);

  return {
    data,
    loading,
    error,
    reset: resetUserVestingData,
  };
};
