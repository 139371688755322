import Dashboard from "../pages/Dashboard";
import TokenManagement from "../pages/TokenManagement";

export interface IRoute {
  id: string;
  title: string;
  path?: string;
  element: () => JSX.Element;
}

const routes: IRoute[] = [
  {
    id: "dashboard",
    title: "Dashboard",
    path: "/",
    element: Dashboard,
  },
  {
    id: "token_management",
    title: "Token Management",
    path: "/token_management",
    element: TokenManagement,
  },
];

export default routes;
